import React from 'react';

const FailureModal = ({ isOpen, message, handleClose }) => {
    if (!isOpen) return null;
    return (
        <div className="failure-modal">
            <h2>Failed</h2>
            <p>{message}</p>
            <button onClick={handleClose}>OK</button>
        </div>
    );
};

export default FailureModal;

// Similarly for SuccessModal.js
