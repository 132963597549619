// EditPointModal.jsx
import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import './SingleEntryModal.css'; // Reuse the same CSS for consistency

const EditPointModal = ({
    isOpen,
    pointData,
    setPointData,
    handleSubmit,
    closeModal,
    modalPosition,
    tenantId,
    isSubmitting,
    setIsSubmitting,
    onDeletePoint,
    handleOpenSuccessModal,
    handleOpenFailureModal,
    handleSessionExpired
}) => {
    const [autoFields, setAutoFields] = useState({
        country: '',
        state: '',
        countyDistrict: '',
        streetName: '',
        postalCode: '',
        physicalAddress: '',
        googleCode: '',
        aimlocate_address: '',
        aimlocate_postal: '',
        aimlocate_direction: '',
    });

    const [manualFields, setManualFields] = useState({
        apn: '',
        noOfFloors: '',
        unitNumber: '',
        entranceAimAddress: '',
        occupancyType: '',
        firstOwnerName: '',
        secondOwnerName: '',
        otherOwnerName: '',
        manualFields: Array(10).fill(''),
    });

    const [validationErrors, setValidationErrors] = useState({});

    const modalRef = useRef(null);
    const headerRef = useRef(null);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        if (isOpen && pointData) {
            setAutoFields({
                country: pointData.country || '',
                state: pointData.state || '',
                countyDistrict: pointData.county_district || '',
                streetName: pointData.street_name || '',
                postalCode: pointData.postal_code || '',
                physicalAddress: pointData.physical_address || '',
                googleCode: pointData.google_code || '',
                aimlocate_address: pointData.aimlocate_address || '',
                aimlocate_postal: pointData.aimlocate_postal || '',
                aimlocate_direction: pointData.aimlocate_direction || '',
            });
            setManualFields({
                apn: pointData.apn_khasra_landrecord_id || '',
                noOfFloors: pointData.no_of_floors || '',
                unitNumber: pointData.unit_number || '',
                entranceAimAddress: pointData.entrance_aim_address || '',
                occupancyType: pointData.occupancy_type || '',
                firstOwnerName: pointData.first_owner_name || '',
                secondOwnerName: pointData.second_owner_name || '',
                otherOwnerName: pointData.other_owner_name || '',
                manualFields: pointData.manual_fields || Array(10).fill(''),
            });
            setValidationErrors({});
        }
    }, [isOpen, pointData]);

    // Handle dragging of the modal
    useEffect(() => {
        if (isOpen && modalRef.current && headerRef.current) {
            handleDragModal(modalRef.current, headerRef.current);
        }
    }, [isOpen]);

    const handleDragModal = useCallback((modalElement, headerElement) => {
        if (!modalElement || !headerElement) return;

        let offsetX = 0,
            offsetY = 0,
            mouseX = 0,
            mouseY = 0;

        const onMouseMove = (e) => {
            e.preventDefault();
            modalElement.style.top = `${e.clientY - offsetY}px`;
            modalElement.style.left = `${e.clientX - offsetX}px`;
        };

        const onMouseUp = () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };

        const onMouseDown = (e) => {
            e.preventDefault();
            mouseX = e.clientX;
            mouseY = e.clientY;

            const rect = modalElement.getBoundingClientRect();
            offsetX = mouseX - rect.left;
            offsetY = mouseY - rect.top;

            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
        };

        headerElement.addEventListener('mousedown', onMouseDown);

        // Cleanup event listeners on unmount
        return () => {
            headerElement.removeEventListener('mousedown', onMouseDown);
        };
    }, []);

    const handleManualFieldChange = (field, value) => {
        if (field.startsWith('manualField')) {
            const index = parseInt(field.replace('manualField', ''), 10) - 1;
            const updatedManualFields = [...manualFields.manualFields];
            updatedManualFields[index] = value;
            setManualFields((prev) => ({
                ...prev,
                manualFields: updatedManualFields,
            }));
        } else {
            setManualFields((prev) => ({
                ...prev,
                [field]: value,
            }));
        }
    };

    const validate = () => {
        const errors = {};
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) {
            return;
        }

        const data = {
            lat_input: pointData.lat_input || pointData.lat,
            lon_input: pointData.lon_input || pointData.lon,
            country: autoFields.country,
            state: autoFields.state,
            county_district: autoFields.countyDistrict,
            street_name: autoFields.streetName,
            postal_code: autoFields.postalCode,
            physical_address: autoFields.physicalAddress,
            google_code: autoFields.googleCode,
            aimlocate_address: autoFields.aimlocate_address,
            aimlocate_postal: autoFields.aimlocate_postal,
            aimlocate_direction: autoFields.aimlocate_direction,
            apn_khasra_landrecord_id: manualFields.apn,
            no_of_floors: parseInt(manualFields.noOfFloors, 10) || 0,
            unit_number: manualFields.unitNumber,
            entrance_aim_address: manualFields.entranceAimAddress,
            occupancy_type: manualFields.occupancyType,
            first_owner_name: manualFields.firstOwnerName,
            second_owner_name: manualFields.secondOwnerName,
            other_owner_name: manualFields.otherOwnerName,
            manual_fields: manualFields.manualFields,
            user_id: pointData.user_id,
            status: pointData.status || 'Under Review',
        };

        setIsSubmitting(true);
        try {
            const response = await axios.put(
                `${API_BASE_URL}/points/${pointData.id}/`,
                data,
                {
                    headers: {
                        'Tenant-ID': String(tenantId),
                        'Content-Type': 'application/json',
                        Authorization: `Token ${localStorage.getItem('token')}`,
                    },
                }
            );

            if (response.status === 200) {
                setPointData(response.data);
                closeModal();
            }
        } catch (error) {
            console.error('Error updating point:', error);
            if (error.response && error.response.status === 401) {
                // Handle session expiration
                handleSessionExpired();
            } else {
                handleOpenFailureModal('Error updating point. Please try again.');
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleDelete = () => {
        onDeletePoint(pointData.id);
    };

    if (!isOpen || !pointData) return null;

    return (
        <div
            className="modal"
            ref={modalRef}
            style={{ top: `${modalPosition.top}px`, left: `${modalPosition.left}px` }}
        >
            <div className="modal-header" ref={headerRef}>
                <div className="fill-details-text">Edit Point</div>

                {/* Icons on the right side (Save, Delete, Cancel) */}
                <div className="header-icons">
                    {/* Save Icon */}
                    <button
                        type="button"
                        className="icon-button"
                        onClick={handleFormSubmit}
                        title="Save"
                    >
                        <i className="fa fa-save fa-lg"></i>
                    </button>

                    {/* Delete Icon */}
                    <button
                        type="button"
                        className="icon-button"
                        onClick={handleDelete}
                        title="Delete"
                    >
                        <i className="fa fa-trash fa-lg"></i>
                    </button>

                    {/* Cancel Icon */}
                    <button
                        type="button"
                        className="icon-button"
                        onClick={closeModal}
                        title="Cancel"
                    >
                        <i className="fa fa-window-close fa-lg"></i>
                    </button>
                </div>
            </div>

            <div className="modal-body">
                {isSubmitting && (
                    <div className="spinner-overlay">
                        <div className="spinner"></div>
                        <p>Loading attributes...</p>
                    </div>
                )}
                <form className="form" onSubmit={handleFormSubmit}>
                    <div className="form-group">
                        <label htmlFor="aimlocate_address">AimLocate Address</label>
                        <input id="aimlocate_address" type="text" value={
                            autoFields.aimlocate_address
                        } readOnly
                               placeholder="AimLocate Address"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="aimlocate_postal">AimLocate Postal Code</label>
                        <input id="aimlocate_postal" type="text" value={autoFields.aimlocate_postal} readOnly
                               placeholder="AimLocate Postal Code"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="aimlocate_direction">AimLocate Direction</label>
                        <input id="aimlocate_direction" type="text" value={autoFields.aimlocate_direction} readOnly
                               placeholder="AimLocate Direction"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="country">Country</label>
                        <input
                            id="country"
                            type="text"
                            value={autoFields.country}
                            readOnly
                            placeholder="Country Name"
                        />
                    </div>
                    {/* Read-Only Coordinates */}
                    <div className="form-group">
                        <label htmlFor="latitude">Latitude</label>
                        <input id="latitude" type="text" value={pointData.lat} readOnly/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="longitude">Longitude</label>
                        <input id="longitude" type="text" value={pointData.lon} readOnly/>
                    </div>

                    {/* Auto-Populated Fields */}

                    <div className="form-group">
                        <label htmlFor="state">State (Admin Level 1)</label>
                        <input
                            id="state"
                            type="text"
                            value={autoFields.state}
                            readOnly
                            placeholder="State"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="countyDistrict">County/District</label>
                        <input
                            id="countyDistrict"
                            type="text"
                            value={autoFields.countyDistrict}
                            readOnly
                            placeholder="County/District"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="streetName">Street Name</label>
                        <input
                            id="streetName"
                            type="text"
                            value={autoFields.streetName}
                            onChange={(e) => setAutoFields((prev) => ({...prev, streetName: e.target.value}))}
                            placeholder="Street Name"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="postalCode">Postal Code/Zip Code</label>
                        <input
                            id="postalCode"
                            type="text"
                            value={autoFields.postalCode}
                            readOnly
                            placeholder="Postal Code"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="physicalAddress">Physical Address</label>
                        <input
                            id="physicalAddress"
                            type="text"
                            value={autoFields.physicalAddress}
                            readOnly
                            placeholder="Physical Address"
                        />
                    </div>
                    {/* Manually Entered Fields */}
                    <div className="form-group">
                        <label htmlFor="apn">Landrecord ID</label>
                        <input
                            id="apn"
                            type="text"
                            value={manualFields.apn}
                            onChange={(e) => handleManualFieldChange('apn', e.target.value)}
                            placeholder="Landrecord ID"
                        />
                        {validationErrors.apn && <span className="error">{validationErrors.apn}</span>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="noOfFloors">No of Floors</label>
                        <input
                            id="noOfFloors"
                            type="number"
                            value={manualFields.noOfFloors}
                            onChange={(e) => handleManualFieldChange('noOfFloors', e.target.value)}
                            placeholder="Number of Floors"
                        />
                        {validationErrors.noOfFloors && <span className="error">{validationErrors.noOfFloors}</span>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="unitNumber">Unit Number</label>
                        <input
                            id="unitNumber"
                            type="text"
                            value={manualFields.unitNumber}
                            onChange={(e) => handleManualFieldChange('unitNumber', e.target.value)}
                            placeholder="Unit Number"
                        />
                        {validationErrors.unitNumber && <span className="error">{validationErrors.unitNumber}</span>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="entranceAimAddress">Entrance Aim Address</label>
                        <input
                            id="entranceAimAddress"
                            type="text"
                            value={manualFields.entranceAimAddress}
                            onChange={(e) => handleManualFieldChange('entranceAimAddress', e.target.value)}
                            placeholder="Entrance Aim Address"
                        />
                        {validationErrors.entranceAimAddress && (
                            <span className="error">{validationErrors.entranceAimAddress}</span>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="occupancyType">Occupancy Type</label>
                        <input
                            id="occupancyType"
                            type="text"
                            value={manualFields.occupancyType}
                            onChange={(e) => handleManualFieldChange('occupancyType', e.target.value)}
                            placeholder="Occupancy Type"
                        />
                        {validationErrors.occupancyType && (
                            <span className="error">{validationErrors.occupancyType}</span>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="firstOwnerName">First Owner Name</label>
                        <input
                            id="firstOwnerName"
                            type="text"
                            value={manualFields.firstOwnerName}
                            onChange={(e) => handleManualFieldChange('firstOwnerName', e.target.value)}
                            placeholder="First Owner Name"
                        />
                        {validationErrors.firstOwnerName && (
                            <span className="error">{validationErrors.firstOwnerName}</span>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="secondOwnerName">Second Owner Name</label>
                        <input
                            id="secondOwnerName"
                            type="text"
                            value={manualFields.secondOwnerName}
                            onChange={(e) => handleManualFieldChange('secondOwnerName', e.target.value)}
                            placeholder="Second Owner Name"
                        />
                        {validationErrors.secondOwnerName && (
                            <span className="error">{validationErrors.secondOwnerName}</span>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="otherOwnerName">Other Owner Name</label>
                        <input
                            id="otherOwnerName"
                            type="text"
                            value={manualFields.otherOwnerName}
                            onChange={(e) => handleManualFieldChange('otherOwnerName', e.target.value)}
                            placeholder="Other Owner Name"
                        />
                        {validationErrors.otherOwnerName && (
                            <span className="error">{validationErrors.otherOwnerName}</span>
                        )}
                    </div>

                    {/* Manual Blank Fields */}
                    {manualFields.manualFields.map((field, index) => (
                        <div className="form-group manual-field" key={`manual-field-${index}`}>
                            <label htmlFor={`manualField${index + 1}`}>Field {index + 1}</label>
                            <input
                                id={`manualField${index + 1}`}
                                type="text"
                                value={field}
                                onChange={(e) => handleManualFieldChange(`manualField${index + 1}`, e.target.value)}
                                placeholder={`Manual Field ${index + 1}`}
                            />
                        </div>
                    ))}

                    {/* Note: No footer here — the icons in the header handle actions */}
                </form>
            </div>
        </div>
    );
};

export default EditPointModal;
