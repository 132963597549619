import React, {useState} from 'react';
import axios from 'axios';

const ResetPasswordModal = ({
    isOpen,
    handleClose,
    handleOpenSuccessModal,
    handleOpenFailureModal,
    handleSessionExpired, // New prop for handling session expiration
}) => {
    // State to manage form data
    const [resetPasswordData, setResetPasswordData] = useState({email: ''});
    // State to manage validation errors
    const [validationErrors, setValidationErrors] = useState({});
    // State to manage submission status
    const [isSubmitting, setIsSubmitting] = useState(false);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    if (!isOpen) return null;

    // Function to validate form fields
    const validateFields = (data) => {
        const errors = {};
        if (!data.email) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
            errors.email = 'Email is invalid';
        }
        return errors;
    };

    // Function to handle the reset password submission
    const handleResetPassword = async () => {
        // Validate the form data
        const errors = validateFields(resetPasswordData);
        setValidationErrors(errors);

        // If there are no validation errors, proceed with the API call
        if (Object.keys(errors).length === 0) {
            setIsSubmitting(true);
            try {
                await axios.post(`${API_BASE_URL}/reset-password/`, resetPasswordData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${localStorage.getItem('token')}`, // Include if authorization is required
                    },
                });
                // On success, trigger the success modal and close the reset password modal
                handleOpenSuccessModal('Password reset done successfully!');
                handleClose();
            } catch (error) {
                console.error('Error resetting password:', error);
                if (error.response && error.response.status === 401) {
                    // Handle session expiration
                    handleSessionExpired();
                } else {
                    handleOpenFailureModal('Failed to reset password for the provided email. Please try again.');
                }
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    // Function to handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setResetPasswordData({ ...resetPasswordData, [name]: value });
    };

    // Function to handle form cancellation
    const handleCancel = () => {
        setResetPasswordData({ email: '' });
        setValidationErrors({});
        handleClose();
    };

    return (
        <div className="custom-modal-overlay">
            <div className="custom-modal">
                <h2>Which user password to reset?</h2>
                <form className="custom-form" onSubmit={(e) => e.preventDefault()}>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Enter User's email"
                            value={resetPasswordData.email}
                            onChange={(e) => setResetPasswordData({...resetPasswordData, email: e.target.value})}
                            required
                        />
                        {validationErrors.email && <span className="error">{validationErrors.email}</span>}
                    </div>
                    <div className="custom-modal-footer">
                        <button
                            type="button"
                            className="custom-submit-btn"
                            onClick={handleResetPassword}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Submitting...' : 'Submit'}
                        </button>
                        <button
                            type="button"
                            className="custom-cancel-btn"
                            onClick={handleCancel}
                            disabled={isSubmitting}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ResetPasswordModal;
