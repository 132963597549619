import React, { useEffect, useState } from 'react';

const Sidebar = ({ role, handleOpenChangePasswordModal, handleOpenAddUserModal, handleOpenResetPasswordModal, handleSignOut }) => {
    const [username, setUsername] = useState(localStorage.getItem('username') || '');
    const [tenantname, setTenantname] = useState(localStorage.getItem('tenantname') || '');
    const [userRole, setUserRole] = useState(localStorage.getItem('role') || role);

    useEffect(() => {
        // Update the state based on localStorage when the component mounts
        setUsername(localStorage.getItem('username') || '');
        setTenantname(localStorage.getItem('tenantname') || '');
        setUserRole(localStorage.getItem('role') || role);
    }, []);

    return (
        <div className="sidebar">
            <div className="logo-container">
                <img src="/logo.png" alt="logo" className="logo"/>
            </div>
            {userRole === 'SuperAdmin' && (
                <>
                    <button className="sidebar-btn" onClick={handleOpenChangePasswordModal}>Change Password</button>
                    <button className="sidebar-btn" onClick={handleOpenResetPasswordModal}>Reset Password</button>
                </>
            )}
            {userRole === 'CustomerAdmin' && (
                <>
                    <button className="sidebar-btn" onClick={handleOpenChangePasswordModal}>Change Password</button>
                    <button className="sidebar-btn" onClick={handleOpenAddUserModal}>Add User</button>
                </>
            )}
            {userRole === 'User' && (
                <button className="sidebar-btn" onClick={handleOpenChangePasswordModal}>Change Password</button>
            )}
            <button className="sidebar-btn" onClick={handleSignOut}>Sign Out</button>
            <div className="user-info">
                <h4>User Details</h4>
                <p className="user-detail"><strong>Email:</strong> {username}</p>
                <p className="user-detail"><strong>Customer:</strong> {tenantname}</p>
            </div>
        </div>
    );
};

export default Sidebar;
