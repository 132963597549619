import React, {useState, useEffect, useRef, useMemo, useCallback} from 'react';
import {MapContainer, TileLayer, LayersControl, useMapEvents, useMap, Marker} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import {FeatureGroup} from 'react-leaflet';
import {EditControl} from 'react-leaflet-draw';
import './MapComponent.css';
import axios from 'axios';
import debounce from 'lodash.debounce';
import Sidebar from './Sidebar';

import 'leaflet-draw/dist/leaflet.draw.css';
import * as turf from '@turf/turf';
import SingleEntryModal from "./modals/SingleEntryModal";
import MultiEntryModal from "./modals/MultiEntryModal";
import AddUserModal from "./modals/AddUserModal";
import ChangePasswordModal from "./modals/ChangePasswordModal";
import ResetPasswordModal from "./modals/ResetPasswordModal";
import SuccessModal from "./modals/SuccessModal";
import FailureModal from "./modals/FailureModal";
import EditPointModal from './modals/EditPointModal';
import UploadShapefileModal from "./modals/UploadShapefileModal";

// New SearchModal import
// You will add the SearchModal component code below.
import SearchModal from "./modals/SearchModal";

const redIcon = new L.Icon({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [21, 34],
    iconAnchor: [10, 34],
    shadowSize: [41, 41]
});

const MapComponent = (props) => {
    const [isSingleModalOpen, setSingleModalOpen] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [isChangePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
    const [isAddUserModalOpen, setAddUserModalOpen] = useState(false);
    const [isResetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
    const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
    const [isFailureModalOpen, setFailureModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [coordinates, setCoordinates] = useState({lat: '', lng: ''});
    const [singleClickMode, setSingleClickMode] = useState(false);
    const [multiClickMode, setMultiClickMode] = useState(false);
    const [clickedPositions, setClickedPositions] = useState([]);
    const [modalPosition, setModalPosition] = useState({top: 0, left: 0});
    const [center, setCenter] = useState(null);
    const [points, setPoints] = useState([]); // To store fetched points
    const mapRef = useRef();
    const modalRef = useRef(null);
    const headerRef = useRef(null);
    const [isRightClickEnabled, setRightClickEnabled] = useState(true);
    const [cursorStyle, setCursorStyle] = useState('grab');
    const disableApiCalls = props.tenantname === 'aimlocate';

    const [currentBaseLayer, setCurrentBaseLayer] = useState('Map');
    const openUploadModal = () => setUploadModalOpen(true);
    const closeUploadModal = () => setUploadModalOpen(false);
    const [selectedPoint, setSelectedPoint] = useState(null);
    const {role, handleLogout, handleSessionExpired} = props; // Destructure handleSessionExpired from props

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isSubmittingMulti, setIsSubmittingMulti] = useState(false);
    // Move blackDotIcon inside the component and use useMemo
    const blackDotIcon = useMemo(() => {
        const color = currentBaseLayer === 'Satellite' ? 'yellow' : 'darkblue';
        return new L.DivIcon({
            html: `<div style="width: 15px; height: 15px; background-color: ${color}; border-radius: 50%;"></div>`,
            className: 'custom-black-dot-icon',
            iconSize: [10, 10],
            iconAnchor: [5, 5],
        });
    }, [currentBaseLayer]);

    const selectedIcon = useMemo(() => {
        return new L.DivIcon({
            html: `<div style="width: 15px; height: 15px; background-color: #FFA500; border-radius: 50%;"></div>`,
            className: 'custom-selected-icon',
            iconSize: [10, 10],
            iconAnchor: [5, 5],
        });
    }, []);

    // New states for handling multiple modal windows
    const [multiModalOpen, setMultiModalOpen] = useState(false);
    const [currentPointIndex, setCurrentPointIndex] = useState(0);
    const [multiPointData, setMultiPointData] = useState([]);
    const [submittedPoint, setSubmittedPoint] = useState(null); // Store the successfully submitted point
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    // State variable to control polygon drawing
    const [startDrawingPolygon, setStartDrawingPolygon] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false); // New state for edit modal
    const [editModalPosition, setEditModalPosition] = useState({top: 0, left: 0}); // Position for edit modal

    // State variable for showing spinner
    const [isDeleting, setIsDeleting] = useState(false);

    // New state variables for tracking deletion progress
    const [pointsDeleted, setPointsDeleted] = useState(0);
    const [totalPointsToDelete, setTotalPointsToDelete] = useState(0);

    // New state for upload modal
    const [isUploadModalOpen, setUploadModalOpen] = useState(false);

    // New state for search modal
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

    const resetModes = () => {
        setSingleClickMode(false);
        setMultiClickMode(false);
        setStartDrawingPolygon(false);
        setRightClickEnabled(true);
    };


    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const axiosRequest = async (config) => {
        try {
            const response = await axios(config);
            return response;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                props.handleSessionExpired();
            } else {
                console.error("API error:", error);
                handleOpenFailureModal('An error occurred. Please try again.');
            }
            throw error;
        }
    };

    useEffect(() => {
        const storedLat = localStorage.getItem('latitude');
        const storedLng = localStorage.getItem('longitude');

        if (storedLat && storedLng) {
            const userLocation = [parseFloat(storedLat), parseFloat(storedLng)];
            setCenter(userLocation);
        } else if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const {latitude, longitude} = position.coords;
                    const userLocation = [latitude, longitude];
                    setCenter(userLocation);
                    localStorage.setItem('latitude', latitude);
                    localStorage.setItem('longitude', longitude);
                },
                (error) => {
                    console.error("Geolocation error:", error.message);
                    setCenter([40.603530, -111.891410]); // Default center
                },
                {enableHighAccuracy: true}
            );
        } else {
            console.log("Geolocation is not supported by this browser.");
            setCenter([13.0134459121, 80.2139687814]); // Default center
        }
    }, []);

    // Remove or comment out this initial data fetching useEffect
    /*
    useEffect(() => {
        axios({
            method: 'get',
            url: 'http://localhost:8000/api/v1/points/nearby',
            params: {lat: 13.9716, lng: 78.5946, zoom_level: 12},
            headers: {
                'Tenant-ID': String(props.tenantId),
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('token')}`
            }
        })
            .then(response => {
                const locations = response.data.map(point => ({
                    id: point.id,
                    lat: point.lat,
                    lng: point.lon
                }));
                setPoints(locations);

                if (locations.length > 0) {
                    setCenter([locations[0].lat, locations[0].lng]);
                    if (mapRef.current) {
                        mapRef.current.flyTo([locations[0].lat, locations[0].lng], 15, {animate: true, duration: 2});
                    }
                }
            })
            .catch(error => {
                console.error('Error fetching location data:', error);
            });
    }, [props.tenantId]);
    */

    const handleMove = () => {
        if (mapRef.current) {
            const mapCenter = mapRef.current.getCenter();
            console.log('Map center:', mapCenter);
        }
    };

    useEffect(() => {
        const leafletContainer = document.querySelector('.leaflet-container');
        if (leafletContainer) {
            leafletContainer.style.cursor = cursorStyle;
        }
    }, [cursorStyle]);

    const tileLayerOptions = {
        tileSize: 256,
        updateWhenIdle: true,
        updateWhenZooming: false,
        updateInterval: 200,
    };

    function normalizeLongitude(lng) {
        while (lng > 180) {
            lng -= 360;
        }
        while (lng < -180) {
            lng += 360;
        }
        return lng;
    }

    const handleMapClick = (e) => {
        const {lat, lng} = e.latlng;
        const normalizedLng = normalizeLongitude(lng);

        if (singleClickMode) {
            setCoordinates({lat, lng: normalizedLng});
            setClickedPositions([{lat, lng: normalizedLng}]);
            openModal(e);
            resetModes();
            setSingleClickMode(false);
        } else if (multiClickMode) {
            setClickedPositions((prevPositions) => [...prevPositions, {lat, lng}]);
            setMultiPointData((prevData) => [
                ...prevData,
                {
                    name: '',
                    description: '',
                    lat_input: lat,
                    lon_input: lng,
                    user_id: props.userId,
                    status: "Under Review",
                    manualFields: {
                        apn: '',
                        noOfFloors: '',
                        unitNumber: '',
                        entranceAimAddress: '',
                        occupancyType: '',
                        firstOwnerName: '',
                        secondOwnerName: '',
                        otherOwnerName: '',
                        manualFields: Array(10).fill(''),
                    },
                    autoFields: {},
                },
            ]);
        }
    };

    const handleNextPoint = () => {
        if (currentPointIndex < clickedPositions.length - 1) {
            setCurrentPointIndex(currentPointIndex + 1);
        }
    };

    const handlePreviousPoint = () => {
        if (currentPointIndex > 0) {
            setCurrentPointIndex(currentPointIndex - 1);
        }
    };


    const handleMapRightClick = (e) => {
        if (!isRightClickEnabled || clickedPositions.length === 0) return;
        setCurrentPointIndex(0);
        openMultiModal(e);
        resetModes();
        setCursorStyle('grab');
    };

    const openModal = (e) => {
        const mapContainer = document.querySelector('.leaflet-container');
        const rect = mapContainer.getBoundingClientRect();
        const modalX = e.originalEvent.clientX - rect.left;
        const modalY = e.originalEvent.clientY - rect.top;
        setModalPosition({top: modalY, left: modalX});
        setSingleModalOpen(true);
        setCursorStyle('grab');
    };


    const openMultiModal = (e) => {
        setMultiModalOpen(true);
        setCursorStyle('grab');
    };

    const closeModal = () => {
        setSingleModalOpen(false);
        setMultiModalOpen(false);
        setCoordinates({lat: '', lng: ''});
        setClickedPositions([]);
        setCurrentPointIndex(0); // Reset currentPointIndex when closing modal
        setMultiPointData([]);
        resetModes(); // Reset modes when closing modal
        setCursorStyle('grab'); // Reset cursor style
    };

    const enableSingleClickMode = () => {
        setSingleClickMode(true);
        setMultiClickMode(false);
        setRightClickEnabled(false);
        setCursorStyle('crosshair');
        setClickedPositions([]);
        setMultiPointData([]);
        setCurrentPointIndex(0);
    };

    const enableMultiClickMode = () => {
        resetModes();
        setMultiClickMode(true);
        setSingleClickMode(false);
        setRightClickEnabled(true);
        setCursorStyle('crosshair');
        setClickedPositions([]);
        setMultiPointData([]);
        setCurrentPointIndex(0);
    };

    const handleMultiPointSubmit = async () => {
        try {
            const dataToSubmit = multiPointData.map(point => ({
                name: point.name,
                description: point.description,
                lat_input: point.lat_input,
                lon_input: point.lon_input,
                country: point.autoFields.country,
                state: point.autoFields.state,
                county_district: point.autoFields.countyDistrict,
                street_name: point.autoFields.streetName,
                postal_code: point.autoFields.postalCode,
                physical_address: point.autoFields.physicalAddress,
                google_code: point.autoFields.googleCode,
                aimlocate_address: point.autoFields?.aimlocateAddress,
                aimlocate_postal: point.autoFields?.aimlocatePostal,
                aimlocate_direction: point.autoFields?.aimlocateDirection,
                reversegeocodeneeded: point.autoFields?.reversegeocodeneeded || 'No',
                apn_khasra_landrecord_id: point.manualFields.apn,
                no_of_floors: parseInt(point.manualFields.noOfFloors, 10) || 0,
                unit_number: point.manualFields.unitNumber,
                entrance_aim_address: point.manualFields.entranceAimAddress,
                occupancy_type: point.manualFields.occupancyType,
                first_owner_name: point.manualFields.firstOwnerName,
                second_owner_name: point.manualFields.secondOwnerName,
                other_owner_name: point.manualFields.otherOwnerName,
                manual_fields: point.manualFields.manualFields,
                user_id: props.userId,
                status: 'Under Review',
            }));

            const response = await axiosRequest({
                method: 'post',
                url: `${API_BASE_URL}/bulk_create/`,
                data: dataToSubmit,
                headers: {
                    'Tenant-ID': String(props.tenantId),
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('token')}`,
                },
            });

            if (response.status === 201) {
                const newPoints = response.data.map(point => ({
                    id: point.id,
                    lat: point.lat,
                    lng: point.lon,
                }));
                setPoints((prevPoints) => [...prevPoints, ...newPoints]);

                handleOpenSuccessModal('Points created successfully!');
            }
        } catch (error) {
            console.error('Error submitting points:', error);
            // If handleSessionExpired is called, user will be redirected
            if (error.response && error.response.status !== 401) {
                handleOpenFailureModal('Error submitting multiple points. Please try again.');
            }
        } finally {
            setMultiModalOpen(false);
            setClickedPositions([]); // Clear clicked positions after submission
            setMultiPointData([]); // Clear multiPointData
            setCurrentPointIndex(0);
            setIsSubmittingMulti(false);
            resetModes(); // Reset modes
            setCursorStyle('grab'); // Reset cursor style
        }
    };


    // Function to fetch points within the current map viewport
    const fetchPointsInView = useCallback(async (mapInstance) => {
        const zoomLevel = mapInstance.getZoom();

        // Set a minimum zoom level to fetch points
        const MIN_ZOOM_LEVEL = 12;

        if (zoomLevel < MIN_ZOOM_LEVEL) {
            // Clear points if zoomed out beyond the minimum level
            setPoints([]);
            return;
        }

        const bounds = mapInstance.getBounds();
        const southWest = bounds.getSouthWest();
        const northEast = bounds.getNorthEast();

        try {
            const response = await axiosRequest({
                method: 'get',
                url: `${API_BASE_URL}/points/visible`,
                params: {
                    southWestLat: southWest.lat,
                    southWestLng: southWest.lng,
                    northEastLat: northEast.lat,
                    northEastLng: northEast.lng,
                },
                headers: {
                    'Tenant-ID': props.tenantId ? String(props.tenantId) : localStorage.getItem('tenant_id'),
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('token')}`
                }
            });

            const locations = response.data.map(point => ({
                id: point.id,
                lat: point.lat,
                lng: point.lon
            }));

            setPoints(locations);
        } catch (error) {
            console.error('Error fetching location data:', error);
            // If handleSessionExpired is called, user will be redirected
            if (error.response && error.response.status !== 401) {
                handleOpenFailureModal('Error fetching location data. Please try again.');
            }
        }
    }, [props.tenantId]);

    // Debounce the fetchPointsInView function
    const debouncedFetchPointsInView = useMemo(
        () => debounce(fetchPointsInView, 500),
        [fetchPointsInView]
    );

    const MapEvents = () => {
        const map = useMap();

        useEffect(() => {
            const handleBaseLayerChange = (e) => {
                setCurrentBaseLayer(e.name);
            };

            map.on('baselayerchange', handleBaseLayerChange);

            // Fetch points when the map is ready
            // fetchPointsInView(map);

            return () => {
                map.off('baselayerchange', handleBaseLayerChange);
            };
        }, []);

        useMapEvents({
            click: handleMapClick,
            moveend: () => {
                debouncedFetchPointsInView(map);

                // Update localStorage with new center coordinates
                const center = map.getCenter();
                localStorage.setItem('latitude', center.lat);
                localStorage.setItem('longitude', center.lng);
            },
            zoomend: () => debouncedFetchPointsInView(map),
            contextmenu: handleMapRightClick,
        });

        return null;
    };


    const handleOpenChangePasswordModal = () => {
        setChangePasswordModalOpen(true);
    };

    const handleCloseChangePasswordModal = () => {
        setChangePasswordModalOpen(false);
    };

    // Handle Add User Modal
    const handleOpenAddUserModal = () => {
        setAddUserModalOpen(true);
    };

    const handleCloseAddUserModal = () => {
        setAddUserModalOpen(false);
    };

    // Handle API Success and Failure Modals
    const handleOpenSuccessModal = (message) => {
        setModalMessage(message);
        setSuccessModalOpen(true);
    };

    const handleOpenFailureModal = (message) => {
        setModalMessage(message);
        setFailureModalOpen(true);
    };

    const handleCloseSuccessModal = () => {
        setSuccessModalOpen(false);
    };

    const handleCloseFailureModal = () => {
        setFailureModalOpen(false);
    };

    // Function to handle adding a new point to 'points' state
    const handleNewPoint = (newPointData) => {
        const newPoint = {
            id: newPointData.id,
            lat: newPointData.lat,
            lng: newPointData.lon, // Assuming API returns 'lon' instead of 'lng'
        };
        setPoints((prevPoints) => {
            // Prevent adding duplicate points
            const exists = prevPoints.some(point => point.id === newPoint.id);
            if (!exists) {
                return [...prevPoints, newPoint];
            } else {
                return prevPoints;
            }
        });
        setClickedPositions([]);
        resetModes(); // Reset modes
        setCursorStyle('grab'); // Reset cursor style
    };

    const handleOpenResetPasswordModal = () => setResetPasswordModalOpen(true);
    const handleCloseResetPasswordModal = () => setResetPasswordModalOpen(false);

    // Function to handle user sign-out
    const handleSignOut = async () => {
        try {
            await axiosRequest({
                method: 'post',
                url: `${API_BASE_URL}/logout/`,
                headers: {
                    Authorization: `Token ${localStorage.getItem('token')}`,
                },
            });
            localStorage.removeItem('token');
            props.handleLogout();
        } catch (error) {
            console.error('Error signing out:', error);
            if (error.response && error.response.status !== 401) {
                handleOpenFailureModal('Error signing out. Please try again.');
            }
        }
    };

    const handleDragModal = (e) => {
        resetModes(); // Reset other modes
        const modal = modalRef.current;
        const header = headerRef.current;

        if (!modal || !header) return;

        let offsetX = 0, offsetY = 0, mouseX = 0, mouseY = 0;

        const onMouseMove = (e) => {
            e.preventDefault();
            modal.style.top = `${e.clientY - offsetY}px`;
            modal.style.left = `${e.clientX - offsetX}px`;
        };

        const onMouseUp = () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };

        const onMouseDown = (e) => {
            e.preventDefault();
            mouseX = e.clientX;
            mouseY = e.clientY;

            const rect = modal.getBoundingClientRect();
            offsetX = mouseX - rect.left;
            offsetY = mouseY - rect.top;

            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
        };

        header.addEventListener('mousedown', onMouseDown);

        // Cleanup event listeners on unmount
        return () => {
            header.removeEventListener('mousedown', onMouseDown);
        };
    };

    useEffect(() => {
        if (isModalOpen || multiModalOpen) {
            handleDragModal();
        }
    }, [isModalOpen, multiModalOpen]);

    const enablePolygonMode = () => {
        setStartDrawingPolygon(true);
        setCursorStyle('crosshair');

        // Disable map interactions while drawing
        if (mapRef.current) {
            mapRef.current.dragging.disable();
            mapRef.current.doubleClickZoom.disable();
            mapRef.current.scrollWheelZoom.disable();
            mapRef.current.boxZoom.disable();
            mapRef.current.keyboard.disable();
        }
    };

    const deletePointsSequentially = async (ids) => {
        setPointsDeleted(0); // Initialize
        const successfullyDeletedIds = [];
        for (const id of ids) {
            try {
                await axiosRequest({
                    method: 'delete',
                    url: `${API_BASE_URL}/points/${id}/`,
                    headers: {
                        'Tenant-ID': String(props.tenantId),
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${localStorage.getItem('token')}`,
                    },
                });
                setPointsDeleted(prev => prev + 1); // Increment
                successfullyDeletedIds.push(id);
            } catch (error) {
                console.error(`Failed to delete point with ID ${id}:`, error);
                handleOpenFailureModal(`Error deleting point with ID ${id}.`);
            }
        }
        setPoints(prevPoints => prevPoints.filter(point => !successfullyDeletedIds.includes(point.id)));
    };

    // Updated onPolygonCreated function
    const onPolygonCreated = async (layer) => {
        debugger;
        let markersInside = []
        setStartDrawingPolygon(false);
        setCursorStyle('grab');

        // Re-enable map interactions after drawing
        if (mapRef.current) {
            mapRef.current.dragging.enable();
            mapRef.current.doubleClickZoom.enable();
            mapRef.current.scrollWheelZoom.enable();
            mapRef.current.boxZoom.enable();
            mapRef.current.keyboard.enable();
        }

        const polygonGeoJSON = layer.toGeoJSON();

        // Check the structure of the GeoJSON polygon
        console.log("Polygon GeoJSON:", JSON.stringify(polygonGeoJSON));

        // Ensure the polygon is a valid GeoJSON object
        if (!polygonGeoJSON || polygonGeoJSON.type !== 'Feature' || polygonGeoJSON.geometry.type !== 'Polygon') {
            console.error("Invalid polygon data");
            return;
        }

        // Filter points that are inside the polygon using Turf.js
        markersInside = points.filter((point) => {
            const pointGeoJSON = {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [point.lng, point.lat], // Ensure correct order: [longitude, latitude]
                },
            };

            // Use Turf.js to check if the point is inside the polygon
            const isInside = turf.booleanPointInPolygon(pointGeoJSON, polygonGeoJSON);
            if (isInside) {
                console.log(`Point inside polygon: ${point.id}, Coordinates: [${point.lat}, ${point.lng}]`);
            }
            return isInside;
        });

        const markersInsideIDs = markersInside.map(point => point.id);

        if (markersInsideIDs.length === 0) {
            console.log('No markers inside the polygon.');
            return;
        }
        // Set total points to delete and initialize pointsDeleted
        setTotalPointsToDelete(markersInsideIDs.length);
        setPointsDeleted(0);
        // Show spinner before deletion starts
        setIsDeleting(true); // <-- Start spinner

        await deletePointsSequentially(markersInsideIDs);
        setIsDeleting(false);
        if (mapRef.current) {
            await fetchPointsInView(mapRef.current);
        }
        // Reset pointsDeleted and totalPointsToDelete
        setPointsDeleted(0);
        setTotalPointsToDelete(0);
        handleOpenSuccessModal('Selected points deleted successfully.');
        resetModes();
        setCursorStyle('grab');

    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape' && startDrawingPolygon) {
                setStartDrawingPolygon(false);
                setCursorStyle('grab');
                resetModes(); // Reset modes

                // Re-enable map interactions
                if (mapRef.current) {
                    mapRef.current.dragging.enable();
                    mapRef.current.doubleClickZoom.enable();
                    mapRef.current.scrollWheelZoom.enable();
                    mapRef.current.boxZoom.enable();
                    mapRef.current.keyboard.enable();
                }

                // Optionally, you can provide feedback to the user
                console.log('Polygon drawing canceled.');
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [startDrawingPolygon]);

    const handleMarkerClick = (pointId, e) => {
        setIsSubmitting(true);
        axiosRequest({
            method: 'get',
            url: `${API_BASE_URL}/points/${pointId}/`,
            headers: {
                'Tenant-ID': String(props.tenantId),
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`,
            },
        })
            .then((response) => {
                setSelectedPoint(response.data);
                setIsEditModalOpen(true);

                // Set modal position
                const mapContainer = document.querySelector('.leaflet-container');
                const rect = mapContainer.getBoundingClientRect();
                const modalX = e.originalEvent.clientX - rect.left;
                const modalY = e.originalEvent.clientY - rect.top;
                setEditModalPosition({top: modalY, left: modalX});
            })
            .catch((error) => {
                console.error('Error fetching point details:', error);
                // If handleSessionExpired is called, user will be redirected
                if (error.response && error.response.status !== 401) {
                    handleOpenFailureModal('Error fetching point details. Please try again.');
                }
            })
            .finally(() => {
                setIsSubmitting(false); // Stop loading spinner
            });
    };

    const handleEditSubmit = () => {
        const data = {
            id: selectedPoint.id,
            country: selectedPoint.autoFields.country,
            state: selectedPoint.autoFields.state,
            county_district: selectedPoint.autoFields.countyDistrict,
            street_name: selectedPoint.autoFields.streetName,
            postal_code: selectedPoint.autoFields.postalCode,
            physical_address: selectedPoint.autoFields.physicalAddress,
            google_code: selectedPoint.autoFields.googleCode,
            apn_khasra_landrecord_id: selectedPoint.manualFields.apn,
            no_of_floors: parseInt(selectedPoint.manualFields.noOfFloors, 10) || 0,
            unit_number: selectedPoint.manualFields.unitNumber,
            entrance_aim_address: selectedPoint.manualFields.entranceAimAddress,
            occupancy_type: selectedPoint.manualFields.occupancyType,
            first_owner_name: selectedPoint.manualFields.firstOwnerName,
            second_owner_name: selectedPoint.manualFields.secondOwnerName,
            other_owner_name: selectedPoint.manualFields.otherOwnerName,
            manual_fields: selectedPoint.manualFields.manualFields,
            lat_input: selectedPoint.lat_input || selectedPoint.lat,
            lon_input: selectedPoint.lon_input || selectedPoint.lon,
            user_id: selectedPoint.user_id,
            status: selectedPoint.status || 'Under Review',
        };

        axiosRequest({
            method: 'put',
            url: `${API_BASE_URL}/points/${selectedPoint.id}/`,
            headers: {
                'Tenant-ID': String(props.tenantId),
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`,
            },
            data: data,
        })
            .then((response) => {
                setPoints((prevPoints) =>
                    prevPoints.map((point) =>
                        point.id === selectedPoint.id ? {...point, ...response.data} : point
                    )
                );
                handleOpenSuccessModal('Point updated successfully!');
                handleCloseEditModal();
            })
            .catch((error) => {
                console.error('Error updating point:', error);
                // If handleSessionExpired is called, user will be redirected
                if (error.response && error.response.status !== 401) {
                    handleOpenFailureModal('Error updating point. Please try again.');
                }
            });
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
        setSelectedPoint(null);
    };

    // Handle point deletion from edit modal
    const handleDeletePoint = async (pointId) => {
        try {
            const response = await axiosRequest({
                method: 'delete',
                url: `${API_BASE_URL}/points/${pointId}/`,
                headers: {
                    'Tenant-ID': String(props.tenantId),
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('token')}`,
                },
            });

            if (response.status === 204) {
                setPoints((prevPoints) => prevPoints.filter((point) => point.id !== pointId));
                handleCloseEditModal();
            }
        } catch (error) {
            console.error('Error deleting point:', error);
            // If handleSessionExpired is called, user will be redirected
            if (error.response && error.response.status !== 401) {
                handleOpenFailureModal('Error deleting point. Please try again.');
            }
        }
    };

    const handleShapefileUploadSuccess = (centroid) => {
        const [latitude, longitude] = centroid;
        const newCenter = [latitude, longitude];
        setCenter(newCenter);
        localStorage.setItem('latitude', latitude);
        localStorage.setItem('longitude', longitude);
        if (mapRef.current) {
            // Ensure mapRef.current is the Leaflet map instance
            mapRef.current.flyTo(newCenter, 19, {animate: true, duration: 2});
        }
    };
    const handleOpenSearchModal = () => {
        setIsSearchModalOpen(true);
    };

    const handleCloseSearchModal = () => {
        setIsSearchModalOpen(false);
    };

    const handleFlyToLocation = (lat, lng, zoom) => {
        console.log('Fly to location:', lat, lng, zoom);
        if (mapRef.current) {
            mapRef.current.flyTo([lat, lng], zoom, {animate: true, duration: 1});
        }
    };
    return (
        <div className="main-container">
            <div className="sidebar">
                <Sidebar
                    role={role}
                    handleOpenChangePasswordModal={handleOpenChangePasswordModal}
                    handleOpenAddUserModal={handleOpenAddUserModal}
                    handleOpenResetPasswordModal={handleOpenResetPasswordModal}
                    handleSignOut={handleSignOut}
                    username={props.username}
                    tenantname={props.tenantname}
                />
            </div>

            <div className="map-section" style={{cursor: cursorStyle}}>
                {center ? (
                    <MapContainer
                        center={center}
                        zoom={19} // Set initial zoom to minimum zoom level
                        minZoom={4}
                        maxNativeZoom={19}
                        worldCopyJump={true}
                        className="map leaflet-container"
                        scrollWheelZoom={true}
                        ref={mapRef}
                    >
                        <LayersControl position="topright">
                            <LayersControl.BaseLayer checked name="Map">
                                <TileLayer
                                    url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic3VqaXRrdW1hcjEyMyIsImEiOiJjbTEweTh5ODMwbGl6Mm1xeTZsc3U5ZWcyIn0.EUteojtdWIqWthH0IKtU2Q`}
                                    attribution="Map data &copy; Mapbox"
                                    maxZoom={22}
                                    {...tileLayerOptions}
                                />
                            </LayersControl.BaseLayer>

                            <LayersControl.BaseLayer name="Satellite">
                                <TileLayer
                                    url={`https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic3VqaXRrdW1hcjEyMyIsImEiOiJjbTEweTh5ODMwbGl6Mm1xeTZsc3U5ZWcyIn0.EUteojtdWIqWthH0IKtU2Q`}
                                    attribution="Map data &copy; Mapbox"
                                    maxZoom={22}
                                    {...tileLayerOptions}
                                />
                            </LayersControl.BaseLayer>
                        </LayersControl>

                        {/* Render the PolygonDrawer when startDrawingPolygon is true */}
                        {startDrawingPolygon && (
                            <PolygonDrawer start={startDrawingPolygon} onCreated={onPolygonCreated}/>
                        )}

                        <MapEvents/>

                        {/* Display markers for fetched points */}
                        {points.map((point, index) => (
                            <Marker
                                key={point.id}
                                position={[point.lat, point.lng]}
                                icon={point.id === selectedPoint?.id ? selectedIcon : blackDotIcon}  // Use the custom black dot icon
                                interactive={true}
                                eventHandlers={{
                                    click: (e) => handleMarkerClick(point.id, e),
                                }}
                            />
                        ))}

                        {clickedPositions.map((position, index) => (
                            <Marker
                                key={index}
                                position={position}
                                icon={index === currentPointIndex ? redIcon : blackDotIcon}
                            />
                        ))}

                        <div className="button-container">
                            <button className="map-button single-entry" onClick={enableSingleClickMode}
                                    title="Single Entry">
                                <i className="fas fa-map-marker-alt"></i>
                            </button>
                            <button className="map-button multi-entry" onClick={enableMultiClickMode}
                                    title="Multi Entry">
                                <i className="fas fa-map"></i>
                            </button>
                            <button className="map-button polygon-entry" onClick={enablePolygonMode}
                                    title="Delete Multi">
                                <i className="fas fa-trash"></i>
                            </button>
                            <button className="map-button upload-shp" onClick={openUploadModal}
                                    title="Upload Shapefile">
                                <i className="fas fa-upload"></i>
                            </button>
                            {/* New Search button */}
                            <button className="map-button search-entry" onClick={handleOpenSearchModal}
                                    title="Search">
                                <i className="fas fa-search"></i>
                            </button>
                        </div>

                        {/* Spinner overlay */}
                        {isDeleting && (
                            <div className="spinner-overlay">
                                <div className="spinner"></div>
                                <progress
                                    value={pointsDeleted}
                                    max={totalPointsToDelete}
                                    style={{width: '40%', height: '10px'}}
                                />
                                <p>{Math.round((pointsDeleted / totalPointsToDelete) * 100)}% completed</p>
                            </div>
                        )}
                    </MapContainer>
                ) : (
                    <div>Loading map...</div>
                )}
            </div>


            {isSingleModalOpen && (
                <SingleEntryModal
                    isOpen={isSingleModalOpen}
                    coordinates={coordinates}
                    modalPosition={modalPosition}
                    closeModal={closeModal}
                    tenantId={props.tenantId}
                    userId={props.userId}
                    handleOpenSuccessModal={handleOpenSuccessModal}
                    handleOpenFailureModal={handleOpenFailureModal}
                    onPointCreated={handleNewPoint}
                    handleSessionExpired={props.handleSessionExpired}
                />
            )}

            {multiModalOpen && (
                <MultiEntryModal
                    isOpen={multiModalOpen}
                    currentPointIndex={currentPointIndex}
                    setCurrentPointIndex={setCurrentPointIndex}
                    multiPointData={multiPointData}
                    setMultiPointData={setMultiPointData}
                    handlePreviousPoint={handlePreviousPoint}
                    handleNextPoint={handleNextPoint}
                    handleMultiPointSubmit={handleMultiPointSubmit}
                    handleOpenFailureModal={handleOpenFailureModal}
                    closeModal={closeModal}
                    handleSessionExpired={props.handleSessionExpired}
                />
            )}

            {isSubmittingMulti && (
                <div className="spinner-overlay">
                    <div className="spinner"></div>
                    <div className="spinner-text">Submitting data... please wait</div>
                </div>
            )}

            {isAddUserModalOpen && (
                <AddUserModal
                    isOpen={isAddUserModalOpen}
                    handleClose={handleCloseAddUserModal}
                    handleOpenSuccessModal={handleOpenSuccessModal}
                    handleOpenFailureModal={handleOpenFailureModal}
                    handleSessionExpired={props.handleSessionExpired}
                />
            )}

            {isChangePasswordModalOpen && (
                <ChangePasswordModal
                    isOpen={isChangePasswordModalOpen}
                    handleClose={handleCloseChangePasswordModal}
                    onSuccess={() => handleOpenSuccessModal('Password changed successfully!')}
                    onFailure={() => handleOpenFailureModal('Password change failed. Please try again.')}
                    handleSessionExpired={props.handleSessionExpired}
                />
            )}


            {isResetPasswordModalOpen && (
                <ResetPasswordModal
                    isOpen={isResetPasswordModalOpen}
                    handleClose={handleCloseResetPasswordModal}
                    handleOpenSuccessModal={handleOpenSuccessModal}
                    handleOpenFailureModal={handleOpenFailureModal}
                />
            )}

            {isEditModalOpen && selectedPoint && (
                <EditPointModal
                    isOpen={isEditModalOpen}
                    pointData={selectedPoint}
                    setPointData={setSelectedPoint}
                    handleSubmit={handleEditSubmit}
                    closeModal={handleCloseEditModal}
                    modalPosition={editModalPosition}
                    tenantId={props.tenantId}
                    isSubmitting={isSubmitting}
                    setIsSubmitting={setIsSubmitting}
                    onDeletePoint={handleDeletePoint}
                    handleOpenSuccessModal={handleOpenSuccessModal}
                    handleOpenFailureModal={handleOpenFailureModal}
                    handleSessionExpired={props.handleSessionExpired}
                />
            )}

            {/* **Updated UploadShapefileModal with onUploadSuccess prop** */}
            {isUploadModalOpen && (
                <UploadShapefileModal
                    isOpen={isUploadModalOpen}
                    closeModal={closeUploadModal}
                    tenantId={props.tenantId}
                    handleOpenSuccessModal={handleOpenSuccessModal}
                    handleOpenFailureModal={handleOpenFailureModal}
                    onUploadSuccess={handleShapefileUploadSuccess}
                    handleSessionExpired={props.handleSessionExpired}
                />
            )}

            {isSuccessModalOpen && (
                <SuccessModal
                    isOpen={isSuccessModalOpen}
                    message={modalMessage}
                    handleClose={handleCloseSuccessModal}
                />
            )}

            {isFailureModalOpen && (
                <FailureModal
                    isOpen={isFailureModalOpen}
                    message={modalMessage}
                    handleClose={handleCloseFailureModal}
                />
            )}

            {isSearchModalOpen && (
                <SearchModal
                    isOpen={isSearchModalOpen}
                    onClose={handleCloseSearchModal}
                    onFlyToLocation={handleFlyToLocation}
                    handleOpenFailureModal={handleOpenFailureModal}
                    handleOpenSuccessModal={handleOpenSuccessModal}
                />
            )}
        </div>
    );
};

const PolygonDrawer = ({start, onCreated}) => {
    const map = useMap();

    useEffect(() => {
        if (!map || !start) return;

        const drawHandler = new L.Draw.Polygon(map);
        drawHandler.enable();

        const onDrawCreated = (e) => {
            const {layerType, layer} = e;
            if (layerType === 'polygon') {
                onCreated(layer);
            }
        };

        map.on(L.Draw.Event.CREATED, onDrawCreated);

        map.dragging.disable();
        map.doubleClickZoom.disable();
        map.scrollWheelZoom.disable();
        map.boxZoom.disable();
        map.keyboard.disable();

        return () => {
            map.off(L.Draw.Event.CREATED, onDrawCreated);
            drawHandler.disable();
            map.dragging.enable();
            map.doubleClickZoom.enable();
            map.scrollWheelZoom.enable();
            map.boxZoom.enable();
            map.keyboard.enable();
        };
    }, [map, start, onCreated]);

    return null;
};

export default MapComponent;
