import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import './SingleEntryModal.css'; // Reuse the same CSS

const MultiEntryModal = ({
                             isOpen,
                             currentPointIndex,
                             setCurrentPointIndex,
                             multiPointData,
                             setMultiPointData,
                             handlePreviousPoint,
                             handleNextPoint,
                             handleMultiPointSubmit,
                             closeModal,
                             handleSessionExpired, // New prop for handling session expiration
                             handleOpenSuccessModal,
                             handleOpenFailureModal,
                         }) => {
    const modalRef = useRef(null);
    const headerRef = useRef(null);

  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Current point from the multiPoint array
  const currentPoint = multiPointData[currentPointIndex];

  // -------------------------------------------
  // 2. Always define useEffects unconditionally
  // -------------------------------------------

  // 2A) Dragging logic effect
  useEffect(() => {
    // We only attach drag handlers if the modal is open
    if (!isOpen) return;
    if (!modalRef.current || !headerRef.current) return;

    let offsetX = 0;
    let offsetY = 0;
    let mouseX = 0;
    let mouseY = 0;

    const modalElement = modalRef.current;
    const headerElement = headerRef.current;

    const onMouseMove = (e) => {
      e.preventDefault();
      modalElement.style.top = `${e.clientY - offsetY}px`;
      modalElement.style.left = `${e.clientX - offsetX}px`;
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    const onMouseDown = (e) => {
      // Only drag if user clicks exactly on the header itself
      if (e.target !== headerElement) return;
      e.preventDefault();
      mouseX = e.clientX;
      mouseY = e.clientY;

      const rect = modalElement.getBoundingClientRect();
      offsetX = mouseX - rect.left;
      offsetY = mouseY - rect.top;

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    };

    headerElement.addEventListener('mousedown', onMouseDown);

    // Cleanup on unmount
    return () => {
      headerElement.removeEventListener('mousedown', onMouseDown);
    };
  }, [isOpen]); // run whenever modal opens/closes

  // --------------------------------------------
  // 3. Reverse geocoding + AimLocate effect
  // --------------------------------------------
  useEffect(() => {
    if (!isOpen) return;
    if (!currentPoint) return;

    // If autoFields exist, skip re-geocoding
    const hasAutoFields =
      currentPoint.autoFields &&
      Object.keys(currentPoint.autoFields).length > 0;

    if (!hasAutoFields) {
      performReverseGeocoding(currentPoint.lat_input, currentPoint.lon_input);
    } else {
      // Already have data
      setIsLoading(false);
    }
  }, [isOpen, currentPoint]);

  // ---------------------------
  // 3. AimLocate helper function
  // ---------------------------
  const calculateAimLocateDetails = (lat, lon) => {
    //
    // 1. Validate range
    //
    if (lat < -90.0 || lat > 90.0 || lon < -180.0 || lon > 180.0) {
      throw new Error("Latitude or Longitude out of range");
    }

    //
    // 2. Adjust longitude if |lon| > 100 by ±180 (unchanged)
    //
    let adjLon = lon;
    if (adjLon > 100) {
      adjLon = adjLon - 180;
    } else if (adjLon < -100) {
      adjLon = adjLon + 180;
    }

    //
    // 3. Determine final two-letter direction
    //    (Added: if original lon is negative and <100 in absolute value,
    //     force the direction to 'W'.)
    //
    const latDir = (lat >= 0) ? "N" : "S";

    let lonDir;
    if (lon < 0 && Math.abs(lon) < 100) {
      // The new requirement:
      lonDir = "W";
    } else {
      // Fall back to using the sign of the *adjusted* longitude
      lonDir = (adjLon >= 0) ? "E" : "W";
    }

    const aimlocateDirection = latDir + lonDir;

    //
    // 4. Compute the 6-digit AIMLOCATE Code (“Postal”)
    //    (No change here)
    //
    function extract3Digits(num) {
      const x = Math.abs(num);
      const intPart = Math.floor(x);

      if (intPart >= 100) {
        return String(intPart).slice(0, 3);
      }
      if (intPart >= 10) {
        // 2-digit integer => integer + 1 decimal digit
        const decDigit = Math.floor((x - intPart) * 10);
        return String(intPart) + String(decDigit);
      }
      // 1-digit integer => prepend '0', then take two decimal digits
      const twoDec = Math.floor((x - intPart) * 100);
      let temp = String(intPart) + String(twoDec).padStart(2, "0");
      temp = "0" + temp;
      return temp.slice(0, 3);
    }

    const lat3 = extract3Digits(lat);
    const lon3 = extract3Digits(adjLon);
    const aimlocatePostal = lat3 + lon3;

    //
    // 5. Compute the 8-digit AIMLOCATE Address
    //    (No change here)
    //
    function extract4Digits(num) {
      const x = Math.abs(num);
      const frac = x - Math.floor(x);
      let fracStr = frac.toString().split(".")[1] || "";
      fracStr = fracStr.padEnd(6, "0");
      return fracStr.slice(1, 5);
    }

    const lat4 = extract4Digits(lat);
    const lon4 = extract4Digits(adjLon);
    const aimlocateAddress = lat4 + lon4;

    //
    // 6. Return the three fields as before
    //
    return {
      aimlocateAddress,
      aimlocatePostal,
      aimlocateDirection,
    };
  };

  // ---------------------------
  // 4. Reverse Geocode function
  // ---------------------------
  const performReverseGeocoding = async (lat, lng) => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://nominatim.openstreetmap.org/reverse', {
        params: {
          format: 'jsonv2',
          lat,
          lon: lng,
        },
      });

      const address = response.data.address;
      // Basic autoFields
      const newAutoFields = {
        country: address.country || '',
        state: address.state || address['state_district'] || '',
        countyDistrict: address.county || address.district || '',
        streetName: address.road || address.street || '',
        postalCode: address.postcode || '',
        physicalAddress: response.data.display_name || '',
        googleCode: response.data.place_id || '',
      };

      // Now also calculate AimLocate
      const {
        aimlocateAddress,
        aimlocatePostal,
        aimlocateDirection,
        reversegeocodeneeded,
      } = calculateAimLocateDetails(lat, lng);

      // Merge them in
      const updatedAutoFields = {
        ...newAutoFields,
        aimlocateAddress,
        aimlocatePostal,
        aimlocateDirection,
        reversegeocodeneeded,
      };

      // Update multiPointData for the currentPointIndex
      const updatedMultiPointData = [...multiPointData];
      updatedMultiPointData[currentPointIndex] = {
        ...currentPoint,
        autoFields: updatedAutoFields,
      };
      setMultiPointData(updatedMultiPointData);
    } catch (error) {
      console.error('Error performing reverse geocoding:', error);
      alert('Reverse geocoding failed. Please cancel and recreate the points if needed.');

      // Even if reverse geocoding fails, set empty autoFields so the UI won't be broken
      const updatedMultiPointData = [...multiPointData];
      updatedMultiPointData[currentPointIndex] = {
        ...currentPoint,
        autoFields: {
          country: '',
          state: '',
          countyDistrict: '',
          streetName: '',
          postalCode: '',
          physicalAddress: '',
          googleCode: '',
          aimlocateAddress: '',
          aimlocatePostal: '',
          aimlocateDirection: '',
          reversegeocodeneeded: 'No',
        },
      };
      setMultiPointData(updatedMultiPointData);
    } finally {
      setIsLoading(false);
    }
  };

  // ------------------------------------
  // 4. Handle field changes
  // ------------------------------------
  const handleManualFieldChange = (field, value) => {
    const updatedManualFields = {
      ...currentPoint.manualFields,
      [field]: value,
    };
    const updatedMultiPointData = [...multiPointData];
    updatedMultiPointData[currentPointIndex] = {
      ...currentPoint,
      manualFields: updatedManualFields,
    };
    setMultiPointData(updatedMultiPointData);
  };

  const handleManualArrayFieldChange = (index, value) => {
    const updatedArray = [...currentPoint.manualFields.manualFields];
    updatedArray[index] = value;

    const updatedManualFields = {
      ...currentPoint.manualFields,
      manualFields: updatedArray,
    };

    const updatedMultiPointData = [...multiPointData];
    updatedMultiPointData[currentPointIndex] = {
      ...currentPoint,
      manualFields: updatedManualFields,
    };
    setMultiPointData(updatedMultiPointData);
  };

  // If you want to let the user edit e.g. streetName from autoFields:
  const handleAutoFieldChange = (field, value) => {
    const updatedAutoFields = {
      ...currentPoint.autoFields,
      [field]: value,
    };

    const updatedMultiPointData = [...multiPointData];
    updatedMultiPointData[currentPointIndex] = {
      ...currentPoint,
      autoFields: updatedAutoFields,
    };
    setMultiPointData(updatedMultiPointData);
  };

    const validate = () => {
        const errors = {};

        // Add validation logic if needed

        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) {
            return;
        }

        setIsSubmitting(true);
        try {
            await handleMultiPointSubmit(); // Assuming this function handles API submission
            closeModal();
        } catch (error) {
            console.error('Error submitting points:', error);
            if (error.response && error.response.status === 401) {
                handleSessionExpired();
            } else {
                handleOpenFailureModal('Error submitting points. Please try again.');
            }
        } finally {
            setIsSubmitting(false);
        }
    };

  // -----------------------------------------------------
  // 8. Return: Only skip rendering if the modal is closed
  // -----------------------------------------------------
  if (!isOpen) {
    return null;
  }

  // If there's no current point, something's off. You might do this:
  if (!currentPoint) {
    return <div style={{ display: 'none' }}></div>;
  }

  // ---------------------------------------------
  // 9. Render the Modal, show spinner if isLoading
  // ---------------------------------------------
  return (
    <div
      className="modal"
      ref={modalRef}
      style={{
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
      }}
    >
      <div className="modal-header" ref={headerRef}>
        <div className="fill-details-text">
          Point {currentPointIndex + 1} of {multiPointData.length}
        </div>

        {/* Right-aligned icons: Previous, Next, Submit, Cancel */}
        <div className="header-icons">
          {/* Previous Button (only if not the first point) */}
          {currentPointIndex > 0 && (
            <button
              type="button"
              className="icon-button"
              onClick={(e) => {
                e.preventDefault();
                if (validate()) {
                  handlePreviousPoint();
                }
              }}
              title="Previous"
              disabled={isSubmitting}
            >
              <i className="fas fa-arrow-circle-left"></i>
            </button>
          )}

          {/* Next Button (only if not the last point) */}
          {currentPointIndex < multiPointData.length - 1 && (
            <button
              type="button"
              className="icon-button"
              onClick={(e) => {
                e.preventDefault();
                if (validate()) {
                  handleNextPoint();
                }
              }}
              title="Next"
              disabled={isSubmitting}
            >
              <i className="fas fa-arrow-circle-right"></i>
            </button>
          )}

          {/* Submit Button (only if on the last point) */}
          {currentPointIndex === multiPointData.length - 1 && (
            <button
              type="button"
              className="icon-button"
              onClick={handleSubmit}
              title="Submit"
              disabled={isSubmitting}
            >
              <i className="fa fa-save fa-lg"></i>
            </button>
          )}

          {/* Cancel Icon always shown */}
          <button
            type="button"
            className="icon-button"
            onClick={closeModal}
            title="Cancel"
            disabled={isSubmitting}
          >
            <i className="fa fa-window-close fa-lg"></i>
          </button>
        </div>
      </div>

      <div className="modal-body">
        {/* If isLoading, show a spinner overlay on top of the form */}
        {isLoading && (
          <div className="spinner-overlay">
            <div className="spinner"></div>
            <p>Loading data...</p>
          </div>
        )}

        {isSubmitting && (
          <div className="spinner-overlay">
            <div className="spinner"></div>
            <p>Submitting...</p>
          </div>
        )}

        {/* We can still render the form, but it will be visually obscured by the spinner if isLoading */}
        <form className="form" onSubmit={handleSubmit}>
          {/* Read-Only Coordinates */}
          <div className="form-group">
            <label htmlFor="latitude">Latitude</label>
            <input
              id="latitude"
              type="text"
              value={currentPoint.lat_input}
              readOnly
            />
          </div>
          <div className="form-group">
            <label htmlFor="longitude">Longitude</label>
            <input
              id="longitude"
              type="text"
              value={currentPoint.lon_input}
              readOnly
            />
          </div>

          {/* AimLocate Fields (read-only, same as SingleEntryModal) */}
          <div className="form-group">
            <label htmlFor="aimlocateAddress">AimLocate Address</label>
            <input
              id="aimlocateAddress"
              type="text"
              value={currentPoint.autoFields?.aimlocateAddress || ''}
              readOnly
            />
          </div>
          <div className="form-group">
            <label htmlFor="aimlocatePostal">AimLocate Postal Code</label>
            <input
              id="aimlocatePostal"
              type="text"
              value={currentPoint.autoFields?.aimlocatePostal || ''}
              readOnly
            />
          </div>
          <div className="form-group">
            <label htmlFor="aimlocateDirection">AimLocate Direction</label>
            <input
              id="aimlocateDirection"
              type="text"
              value={currentPoint.autoFields?.aimlocateDirection || ''}
              readOnly
            />
          </div>

          {/* If you want to display or store reversegeocodeneeded, you can keep it hidden */}
          <input
            type="hidden"
            value={currentPoint.autoFields?.reversegeocodeneeded || 'No'}
            readOnly
            name="reversegeocodeneeded"
          />

          {/* Auto-Populated Fields */}
          <div className="form-group">
            <label htmlFor="country">Country</label>
            <input
              id="country"
              type="text"
              value={currentPoint.autoFields?.country || ''}
              readOnly
              placeholder="Country Name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="state">State (Admin Level 1)</label>
            <input
              id="state"
              type="text"
              value={currentPoint.autoFields?.state || ''}
              readOnly
              placeholder="State"
            />
          </div>
          <div className="form-group">
            <label htmlFor="countyDistrict">County/District</label>
            <input
              id="countyDistrict"
              type="text"
              value={currentPoint.autoFields?.countyDistrict || ''}
              readOnly
              placeholder="County/District"
            />
          </div>
          <div className="form-group">
            <label htmlFor="streetName">Street Name</label>
            <input
              id="streetName"
              type="text"
              value={currentPoint.autoFields?.streetName || ''}
              onChange={(e) => handleAutoFieldChange('streetName', e.target.value)}
              placeholder="Street Name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="postalCode">Postal Code/Zip Code</label>
            <input
              id="postalCode"
              type="text"
              value={currentPoint.autoFields?.postalCode || ''}
              readOnly
              placeholder="Postal Code"
            />
          </div>
          <div className="form-group">
            <label htmlFor="physicalAddress">Physical Address</label>
            <input
              id="physicalAddress"
              type="text"
              value={currentPoint.autoFields?.physicalAddress || ''}
              readOnly
              placeholder="Physical Address"
            />
          </div>
          <div className="form-group">
            <label htmlFor="googleCode">Google Code</label>
            <input
              id="googleCode"
              type="text"
              value={currentPoint.autoFields?.googleCode || ''}
              readOnly
              placeholder="Google Code"
            />
          </div>

          {/* Manually Entered Fields */}
          <div className="form-group">
            <label htmlFor="apn">Landrecord ID</label>
            <input
              id="apn"
              type="text"
              value={currentPoint.manualFields?.apn || ''}
              onChange={(e) => handleManualFieldChange('apn', e.target.value)}
              placeholder="Landrecord ID"
            />
            {validationErrors.apn && (
              <span className="error">{validationErrors.apn}</span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="noOfFloors">No of Floors</label>
            <input
              id="noOfFloors"
              type="number"
              value={currentPoint.manualFields?.noOfFloors || ''}
              onChange={(e) => handleManualFieldChange('noOfFloors', e.target.value)}
              placeholder="Number of Floors"
            />
            {validationErrors.noOfFloors && (
              <span className="error">{validationErrors.noOfFloors}</span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="unitNumber">Unit Number</label>
            <input
              id="unitNumber"
              type="text"
              value={currentPoint.manualFields?.unitNumber || ''}
              onChange={(e) => handleManualFieldChange('unitNumber', e.target.value)}
              placeholder="Unit Number"
            />
            {validationErrors.unitNumber && (
              <span className="error">{validationErrors.unitNumber}</span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="entranceAimAddress">Entrance Aim Address</label>
            <input
              id="entranceAimAddress"
              type="text"
              value={currentPoint.manualFields?.entranceAimAddress || ''}
              onChange={(e) =>
                handleManualFieldChange('entranceAimAddress', e.target.value)
              }
              placeholder="Entrance Aim Address"
            />
            {validationErrors.entranceAimAddress && (
              <span className="error">{validationErrors.entranceAimAddress}</span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="occupancyType">Occupancy Type</label>
            <input
              id="occupancyType"
              type="text"
              value={currentPoint.manualFields?.occupancyType || ''}
              onChange={(e) => handleManualFieldChange('occupancyType', e.target.value)}
              placeholder="Occupancy Type"
            />
            {validationErrors.occupancyType && (
              <span className="error">{validationErrors.occupancyType}</span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="firstOwnerName">First Owner Name</label>
            <input
              id="firstOwnerName"
              type="text"
              value={currentPoint.manualFields?.firstOwnerName || ''}
              onChange={(e) =>
                handleManualFieldChange('firstOwnerName', e.target.value)
              }
              placeholder="First Owner Name"
            />
            {validationErrors.firstOwnerName && (
              <span className="error">{validationErrors.firstOwnerName}</span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="secondOwnerName">Second Owner Name</label>
            <input
              id="secondOwnerName"
              type="text"
              value={currentPoint.manualFields?.secondOwnerName || ''}
              onChange={(e) =>
                handleManualFieldChange('secondOwnerName', e.target.value)
              }
              placeholder="Second Owner Name"
            />
            {validationErrors.secondOwnerName && (
              <span className="error">{validationErrors.secondOwnerName}</span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="otherOwnerName">Other Owner Name</label>
            <input
              id="otherOwnerName"
              type="text"
              value={currentPoint.manualFields?.otherOwnerName || ''}
              onChange={(e) =>
                handleManualFieldChange('otherOwnerName', e.target.value)
              }
              placeholder="Other Owner Name"
            />
            {validationErrors.otherOwnerName && (
              <span className="error">{validationErrors.otherOwnerName}</span>
            )}
          </div>

          {/* Extra manual fields array */}
          {currentPoint.manualFields?.manualFields?.map((fieldValue, index) => (
            <div className="form-group manual-field" key={`manual-field-${index}`}>
              <label htmlFor={`manualField${index + 1}`}>
                Field {index + 1}
              </label>
              <input
                id={`manualField${index + 1}`}
                type="text"
                value={fieldValue}
                onChange={(e) =>
                  handleManualArrayFieldChange(index, e.target.value)
                }
                placeholder={`Manual Field ${index + 1}`}
              />
            </div>
          ))}
        </form>
      </div>
    </div>
  );
};

export default MultiEntryModal;
