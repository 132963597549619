// src/modals/AddUserModal.js
import React, { useState } from 'react';
import axios from 'axios';
import './AddUserModal.css'; // Ensure the CSS is imported

const AddUserModal = ({
    isOpen,
    handleClose,
    handleOpenSuccessModal,
    handleOpenFailureModal,
    handleSessionExpired // New prop for handling session expiration
}) => {
    const [newUserData, setNewUserData] = useState({
        name: '',
        email: '',
        role: 'User', // Set default value to 'User'
        tenant: '',
        password: ''
    });
    const [validationErrors, setValidationErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    if (!isOpen) return null;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewUserData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateFields = (data, fields) => {
        const errors = {};
        fields.forEach((field) => {
            if (!data[field]) {
                errors[field] = 'This field is required';
            }
        });
        return errors;
    };

    const handleAddUser = async () => {
        const errors = validateFields(newUserData, ['name', 'email', 'role', 'tenant', 'password']);
        setValidationErrors(errors);
        if (Object.keys(errors).length === 0) {
            setIsSubmitting(true);
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/`, newUserData, {
                    headers: {
                        Authorization: `Token ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                });
                handleOpenSuccessModal('User added successfully!');
                handleClose();
            } catch (error) {
                console.error('Error adding user:', error);
                if (error.response && error.response.status === 401) {
                    handleSessionExpired();
                } else if (error.response && error.response.data) {
                    // Extract the error message from the response
                    const errorMessage = error.response.data.email?.[0] || 'An error occurred while adding the user.';
                    const capitalizedErrorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
                    handleOpenFailureModal(capitalizedErrorMessage);
                } else {
                    handleOpenFailureModal('An unknown error occurred.');
                }
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    const handleCancel = () => {
        setNewUserData({
            name: '',
            email: '',
            role: 'User',
            tenant: '',
            password: ''
        });
        setValidationErrors({});
        handleClose();
    };

    return (
        <div className="custom-modal-overlay">
            <div className="custom-modal">
                <div className="custom-modal-header">
                    <h2>Add User</h2>
                    <button className="close-button" onClick={handleCancel}>×</button>
                </div>
                <div className="custom-modal-body">
                    <form className="custom-form" onSubmit={(e) => e.preventDefault()}>
                        <div className="form-group">
                            <label htmlFor="name">Name:</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={newUserData.name}
                                onChange={handleChange}
                            />
                            {validationErrors.name && <span className="error">{validationErrors.name}</span>}
                        </div>

                        <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={newUserData.email}
                                onChange={handleChange}
                            />
                            {validationErrors.email && <span className="error">{validationErrors.email}</span>}
                        </div>

                        <div className="form-group">
                            <label htmlFor="role">Role:</label>
                            <select
                                id="role"
                                name="role"
                                value={newUserData.role}
                                onChange={handleChange}
                            >
                                <option value="User">User</option>
                                <option value="CustomerAdmin">Customer Admin</option>
                                <option value="SuperAdmin">Super Admin</option>
                            </select>
                            {validationErrors.role && <span className="error">{validationErrors.role}</span>}
                        </div>

                        <div className="form-group">
                            <label htmlFor="tenant">Tenant:</label>
                            <input
                                type="text"
                                id="tenant"
                                name="tenant"
                                value={newUserData.tenant}
                                onChange={handleChange}
                            />
                            {validationErrors.tenant && <span className="error">{validationErrors.tenant}</span>}
                        </div>

                        <div className="form-group">
                            <label htmlFor="password">Password:</label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={newUserData.password}
                                onChange={handleChange}
                            />
                            {validationErrors.password && <span className="error">{validationErrors.password}</span>}
                        </div>
                    </form>
                </div>
                <div className="custom-modal-footer">
                    <button type="button" className="custom-submit-btn" onClick={handleAddUser} disabled={isSubmitting}>
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                    </button>
                    <button type="button" className="custom-cancel-btn" onClick={handleCancel} disabled={isSubmitting}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );

};

export default AddUserModal;
