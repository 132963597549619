import React from 'react';

const SuccessModal = ({ isOpen, message, handleClose }) => {
    if (!isOpen) return null;
    return (
        <div className="success-modal">
            <h2>Success</h2>
            <p>{message}</p>
            <button onClick={handleClose}>OK</button>
        </div>
    );
};

export default SuccessModal;

// Similarly for SuccessModal.js
