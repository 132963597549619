import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import './SingleEntryModal.css'; // Ensure you have appropriate styles

const SingleEntryModal = ({
  isOpen,
  coordinates,
  modalPosition,
  closeModal,
  tenantId,
  userId,
  handleOpenFailureModal,
  onPointCreated,
  handleSessionExpired,
}) => {
    const [autoFields, setAutoFields] = useState({
        country: '',
        state: '',
        countyDistrict: '',
        streetName: '',
        postalCode: '',
        physicalAddress: '',
        googleCode: '',
        aimlocateAddress: '',
        aimlocatePostal: '',
        aimlocateDirection: '',
        reversegeocodeneeded: 'No',
    });

    // Manually entered fields
    const [manualFields, setManualFields] = useState({
        apn: '',
        noOfFloors: '',
        unitNumber: '',
        entranceAimAddress: '',
        occupancyType: '',
        firstOwnerName: '',
        secondOwnerName: '',
        otherOwnerName: '',
        manualFields: Array(10).fill(''),
    });

    const [validationErrors, setValidationErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false); // New state for submission status

    const localModalRef = useRef(null);
    const localHeaderRef = useRef(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const calculateAimLocateDetails = (lat, lon) => {
  //
  // 1. Validate range
  //
  if (lat < -90.0 || lat > 90.0 || lon < -180.0 || lon > 180.0) {
    throw new Error("Latitude or Longitude out of range");
  }

  //
  // 2. Adjust longitude if |lon| > 100 by ±180 (unchanged)
  //
  let adjLon = lon;
  if (adjLon > 100) {
    adjLon = adjLon - 180;
  } else if (adjLon < -100) {
    adjLon = adjLon + 180;
  }

  //
  // 3. Determine final two-letter direction
  //    (Added: if original lon is negative and <100 in absolute value,
  //     force the direction to 'W'.)
  //
  const latDir = (lat >= 0) ? "N" : "S";

  let lonDir;
  if (lon < 0 && Math.abs(lon) < 100) {
    // The new requirement:
    lonDir = "W";
  } else {
    // Fall back to using the sign of the *adjusted* longitude
    lonDir = (adjLon >= 0) ? "E" : "W";
  }

  const aimlocateDirection = latDir + lonDir;

  //
  // 4. Compute the 6-digit AIMLOCATE Code (“Postal”)
  //    (No change here)
  //
  function extract3Digits(num) {
    const x = Math.abs(num);
    const intPart = Math.floor(x);

    if (intPart >= 100) {
      return String(intPart).slice(0, 3);
    }
    if (intPart >= 10) {
      // 2-digit integer => integer + 1 decimal digit
      const decDigit = Math.floor((x - intPart) * 10);
      return String(intPart) + String(decDigit);
    }
    // 1-digit integer => prepend '0', then take two decimal digits
    const twoDec = Math.floor((x - intPart) * 100);
    let temp = String(intPart) + String(twoDec).padStart(2, "0");
    temp = "0" + temp;
    return temp.slice(0, 3);
  }

  const lat3 = extract3Digits(lat);
  const lon3 = extract3Digits(adjLon);
  const aimlocatePostal = lat3 + lon3;

  //
  // 5. Compute the 8-digit AIMLOCATE Address
  //    (No change here)
  //
  function extract4Digits(num) {
    const x = Math.abs(num);
    const frac = x - Math.floor(x);
    let fracStr = frac.toString().split(".")[1] || "";
    fracStr = fracStr.padEnd(6, "0");
    return fracStr.slice(1, 5);
  }

  const lat4 = extract4Digits(lat);
  const lon4 = extract4Digits(adjLon);
  const aimlocateAddress = lat4 + lon4;

  //
  // 6. Return the three fields as before
  //
  return {
    aimlocateAddress,
    aimlocatePostal,
    aimlocateDirection,
  };
};



// 2) Use the function to get the computed values:
const SingleEntryModal = ({ latitude, longitude }) => {
  const { aimlocateAddress, aimlocatePostal, aimlocateDirection } =
    calculateAimLocateDetails(latitude, longitude);

  // Now you have those 3 values that you can use in your JSX or anywhere else:
  console.log("Aimlocate Postal =>", aimlocatePostal);
  console.log("Aimlocate Address =>", aimlocateAddress);
  console.log("Aimlocate Direction =>", aimlocateDirection);

  return (
    <div>
      <p>AC: {aimlocatePostal}</p>
      <p>AA: {aimlocateAddress}</p>
      <p>D: {aimlocateDirection}</p>
      {/* ... the rest of your modal ... */}
    </div>
  );
};

    const performReverseGeocoding = async (coords) => {
        try {
            // Example using OpenStreetMap's Nominatim API
            const response = await axios.get('https://nominatim.openstreetmap.org/reverse', {
                params: {
                    format: 'jsonv2',
                    lat: coords.lat,
                    lon: coords.lng,
                },
            });

            const address = response.data.address;

            setAutoFields((prev) => ({
                ...prev,
                country: address.country || '',
                state: address.state || address['state_district'] || '',
                countyDistrict: address.county || address.district || '',
                streetName: address.road || address.street || '',
                postalCode: address.postcode || '',
                physicalAddress: response.data.display_name || '',
                googleCode: response.data.place_id || '', // Replace with actual Google Code if available
            }));
        } catch (error) {
            console.error('Error performing reverse geocoding:', error);
            // Optionally, handle the error (e.g., show a notification)
        }
    };

    // Re-initialize state when modal opens, then chain the reverse-geocoding and AimLocate
    useEffect(() => {
        if (isOpen) {
        setIsProcessing(true);
            // Reset form states
            setAutoFields({
                country: '',
                state: '',
                countyDistrict: '',
                streetName: '',
                postalCode: '',
                physicalAddress: '',
                googleCode: '',
                aimlocateAddress: '',
                aimlocatePostal: '',
                aimlocateDirection: '',
                reversegeocodeneeded: 'No',
            });
            setManualFields({
                apn: '',
                noOfFloors: '',
                unitNumber: '',
                entranceAimAddress: '',
                occupancyType: '',
                firstOwnerName: '',
                secondOwnerName: '',
                otherOwnerName: '',
                manualFields: Array(10).fill(''),
            });
            setValidationErrors({});

            // Perform geocoding first (async) then set aimLocate fields
            performReverseGeocoding(coordinates)
              .then(() => {
                  try {
                      const { aimlocateAddress, aimlocatePostal, aimlocateDirection } =
                        calculateAimLocateDetails(coordinates.lat, coordinates.lng);
                      setAutoFields((prev) => ({
                          ...prev,
                          aimlocateAddress,
                          aimlocatePostal,
                          aimlocateDirection,
                      }));
                  } catch (error) {
                      console.error("Error calculating AimLocate details:", error);
                  }
              })
              .finally(() => setIsProcessing(false));
        }
    }, [isOpen, coordinates]);

    // Handle dragging of the modal
    useEffect(() => {
        if (isOpen && localModalRef.current && localHeaderRef.current) {
            handleDragModal(localModalRef.current, localHeaderRef.current);
        }
    }, [isOpen]);

    const handleDragModal = useCallback((modalElement, headerElement) => {
        if (!modalElement || !headerElement) return;

        let offsetX = 0,
            offsetY = 0,
            mouseX = 0,
            mouseY = 0;

        const onMouseMove = (e) => {
            e.preventDefault();
            modalElement.style.top = `${e.clientY - offsetY}px`;
            modalElement.style.left = `${e.clientX - offsetX}px`;
        };

        const onMouseUp = () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };

        const onMouseDown = (e) => {
            e.preventDefault();
            mouseX = e.clientX;
            mouseY = e.clientY;

            const rect = modalElement.getBoundingClientRect();
            offsetX = mouseX - rect.left;
            offsetY = mouseY - rect.top;

            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
        };

        headerElement.addEventListener('mousedown', onMouseDown);

        // Cleanup event listeners on unmount
        return () => {
            headerElement.removeEventListener('mousedown', onMouseDown);
        };
    }, []);

    const handleManualFieldChange = (field, value) => {
        if (field.startsWith('manualField')) {
            const index = parseInt(field.replace('manualField', ''), 10) - 1;
            const updatedManualFields = [...manualFields.manualFields];
            updatedManualFields[index] = value;
            setManualFields((prev) => ({
                ...prev,
                manualFields: updatedManualFields,
            }));
        } else {
            setManualFields((prev) => ({
                ...prev,
                [field]: value,
            }));
        }
    };

    const validate = () => {
        const errors = {};
        // Add more validations as necessary
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

  // 7) Compute AimLocate & submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    setIsSubmitting(true);

    // Make sure coords exist
    if (!coordinates || coordinates.lat == null || coordinates.lng == null) {
      console.error('No valid coordinates to calculate AimLocate. coords=', coordinates);
      setIsSubmitting(false);
      return;
    }

    // Just-in-time calculation
    let aimlocateAddress = '';
    let aimlocatePostal = '';
    let aimlocateDirection = '';
    try {
      const result = calculateAimLocateDetails(coordinates.lat, coordinates.lng);
      aimlocateAddress = result.aimlocateAddress;
      aimlocatePostal = result.aimlocatePostal;
      aimlocateDirection = result.aimlocateDirection;
    } catch (err) {
      console.error('Error calculating AimLocate details:', err);
    }

    // 2) Build data with up-to-date AimLocate values
    const data = {
      lat_input: coordinates.lat,
      lon_input: coordinates.lng,
      country: autoFields.country,
      state: autoFields.state,
      county_district: autoFields.countyDistrict,
      street_name: autoFields.streetName,
      postal_code: autoFields.postalCode,
      physical_address: autoFields.physicalAddress,
      google_code: autoFields.googleCode,
      apn_khasra_landrecord_id: manualFields.apn,
      no_of_floors: parseInt(manualFields.noOfFloors, 10) || 0,
      unit_number: manualFields.unitNumber,
      entrance_aim_address: manualFields.entranceAimAddress,
      occupancy_type: manualFields.occupancyType,
      first_owner_name: manualFields.firstOwnerName,
      second_owner_name: manualFields.secondOwnerName,
      other_owner_name: manualFields.otherOwnerName,
      manual_fields: manualFields.manualFields,
      user_id: userId,
      status: 'Under Review',
      aimlocate_address: aimlocateAddress,
      aimlocate_postal: aimlocatePostal,
      aimlocate_direction: aimlocateDirection,
      reversegeocodeneeded: autoFields.reversegeocodeneeded,
    };

        console.log("Submitting data: ", data);
        setIsSubmitting(true);

    try {
      const response = await axios.post(
        `${API_BASE_URL}/points/create/`,
        data,
        {
          headers: {
            'Tenant-ID': String(tenantId),
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        }
      );

            if (response.status === 201 && onPointCreated) {
                onPointCreated(response.data);
                closeModal();
            }
        } catch (error) {
            console.error('Error submitting point:', error);
            if (error.response && error.response.status === 401) {
                handleSessionExpired();
            } else {
                handleOpenFailureModal('Error submitting point. Please try again.');
            }
        } finally {
            setIsSubmitting(false); // End submission
        }
    };

    if (!isOpen) return null;

  return (
    <div
      className="modal"
      ref={localModalRef}
      style={{ top: `${modalPosition.top}px`, left: `${modalPosition.left}px` }}
    >
      {isProcessing && (
        <div className="spinner-overlay">
          <div className="spinner"></div>
          <p>Processing...</p>
        </div>
      )}
            <div className="modal-header" ref={localHeaderRef}>
                <div className="fill-details-text">Fill Details</div>
                {/* Header Icons for Submit & Cancel */}
                <div className="header-icons">
                    {/* Submit Icon */}
                    <button
                        type="button"
                        className="icon-button"
                        onClick={handleSubmit}
                        title="Save"
                    >
                        <i className="fa fa-save fa-lg"></i>
                    </button>

                    {/* Cancel Icon */}
                    <button
                        type="button"
                        className="icon-button"
                        onClick={closeModal}
                        title="Cancel"
                    >
                        <i className="fa fa-window-close fa-lg"></i>
                    </button>
                </div>
            </div>
            <div className="modal-body">
                {isSubmitting && (
                    <div className="spinner-overlay">
                        <div className="spinner"></div>
                        <p>Submitting...</p>
                    </div>
                )}
                <form className="form" onSubmit={handleSubmit}>
                    {/* Read-Only Coordinates */}
                    <div className="form-group">
                        <label htmlFor="latitude">Latitude</label>
                        <input id="latitude" type="text" value={coordinates.lat} readOnly/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="longitude">Longitude</label>
                        <input id="longitude" type="text" value={coordinates.lng} readOnly/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="aimlocateAddress">AimLocate Address</label>
                        <input id="aimlocateAddress" type="text" value={autoFields.aimlocateAddress} readOnly/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="aimlocatePostal">AimLocate Postal Code</label>
                        <input id="aimlocatePostal" type="text" value={autoFields.aimlocatePostal} readOnly/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="aimlocateDirection">AimLocate Direction</label>
                        <input id="aimlocateDirection" type="text" value={autoFields.aimlocateDirection} readOnly/>
                    </div>

                    {/* Auto-Populated Fields */}
                    <div className="form-group">
                        <label htmlFor="country">Country</label>
                        <input
                            id="country"
                            type="text"
                            value={autoFields.country}
                            readOnly
                            placeholder="Country Name"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="state">State (Admin Level 1)</label>
                        <input
                            id="state"
                            type="text"
                            value={autoFields.state}
                            readOnly
                            placeholder="State"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="countyDistrict">County/District</label>
                        <input
                            id="countyDistrict"
                            type="text"
                            value={autoFields.countyDistrict}
                            readOnly
                            placeholder="County/District"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="streetName">Street Name</label>
                        <input
                            id="streetName"
                            type="text"
                            value={autoFields.streetName}
                            onChange={(e) => setAutoFields((prev) => ({...prev, streetName: e.target.value}))}
                            placeholder="Street Name"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="postalCode">Postal Code/Zip Code</label>
                        <input
                            id="postalCode"
                            type="text"
                            value={autoFields.postalCode}
                            readOnly
                            placeholder="Postal Code"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="physicalAddress">Physical Address</label>
                        <input
                            id="physicalAddress"
                            type="text"
                            value={autoFields.physicalAddress}
                            readOnly
                            placeholder="Physical Address"
                        />
                    </div>

                    {/* Manually Entered Fields */}
                    <div className="form-group">
                        <label htmlFor="apn">Landrecord ID</label>
                        <input
                            id="apn"
                            type="text"
                            value={manualFields.apn}
                            onChange={(e) => handleManualFieldChange('apn', e.target.value)}
                            placeholder="Landrecord ID"
                        />
                        {validationErrors.apn && <span className="error">{validationErrors.apn}</span>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="noOfFloors">No of Floors</label>
                        <input
                            id="noOfFloors"
                            type="number"
                            value={manualFields.noOfFloors}
                            onChange={(e) => handleManualFieldChange('noOfFloors', e.target.value)}
                            placeholder="Number of Floors"
                        />
                        {validationErrors.noOfFloors && <span className="error">{validationErrors.noOfFloors}</span>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="unitNumber">Unit Number</label>
                        <input
                            id="unitNumber"
                            type="text"
                            value={manualFields.unitNumber}
                            onChange={(e) => handleManualFieldChange('unitNumber', e.target.value)}
                            placeholder="Unit Number"
                        />
                        {validationErrors.unitNumber && <span className="error">{validationErrors.unitNumber}</span>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="entranceAimAddress">Entrance Aim Address</label>
                        <input
                            id="entranceAimAddress"
                            type="text"
                            value={manualFields.entranceAimAddress}
                            onChange={(e) => handleManualFieldChange('entranceAimAddress', e.target.value)}
                            placeholder="Entrance Aim Address"
                        />
                        {validationErrors.entranceAimAddress && (
                            <span className="error">{validationErrors.entranceAimAddress}</span>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="occupancyType">Occupancy Type</label>
                        <input
                            id="occupancyType"
                            type="text"
                            value={manualFields.occupancyType}
                            onChange={(e) => handleManualFieldChange('occupancyType', e.target.value)}
                            placeholder="Occupancy Type"
                        />
                        {validationErrors.occupancyType && (
                            <span className="error">{validationErrors.occupancyType}</span>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="firstOwnerName">First Owner Name</label>
                        <input
                            id="firstOwnerName"
                            type="text"
                            value={manualFields.firstOwnerName}
                            onChange={(e) => handleManualFieldChange('firstOwnerName', e.target.value)}
                            placeholder="First Owner Name"
                        />
                        {validationErrors.firstOwnerName && (
                            <span className="error">{validationErrors.firstOwnerName}</span>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="secondOwnerName">Second Owner Name</label>
                        <input
                            id="secondOwnerName"
                            type="text"
                            value={manualFields.secondOwnerName}
                            onChange={(e) => handleManualFieldChange('secondOwnerName', e.target.value)}
                            placeholder="Second Owner Name"
                        />
                        {validationErrors.secondOwnerName && (
                            <span className="error">{validationErrors.secondOwnerName}</span>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="otherOwnerName">Other Owner Name</label>
                        <input
                            id="otherOwnerName"
                            type="text"
                            value={manualFields.otherOwnerName}
                            onChange={(e) => handleManualFieldChange('otherOwnerName', e.target.value)}
                            placeholder="Other Owner Name"
                        />
                        {validationErrors.otherOwnerName && (
                            <span className="error">{validationErrors.otherOwnerName}</span>
                        )}
                    </div>

                    {/* Manual Blank Fields */}
                    {manualFields.manualFields.map((field, index) => (
                        <div className="form-group manual-field" key={`manual-field-${index}`}>
                            <label htmlFor={`manualField${index + 1}`}>Field {index + 1}</label>
                            <input
                                id={`manualField${index + 1}`}
                                type="text"
                                value={field}
                                onChange={(e) => handleManualFieldChange(`manualField${index + 1}`, e.target.value)}
                                placeholder={`Manual Field ${index + 1}`}
                            />
                        </div>
                    ))}

                    {/*
                        Footer removed:
                        <div className="modal-footer">
                            <button type="submit" className="submit-button">Submit</button>
                            <button type="button" className="cancel-button" onClick={closeModal}>Cancel</button>
                        </div>
                    */}
                </form>
            </div>
        </div>
    );
};

export default SingleEntryModal;
