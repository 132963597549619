import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import './UploadShapefileModal.css'; // Import the updated CSS file

const UploadShapefileModal = ({ isOpen, closeModal, tenantId, handleOpenSuccessModal, handleOpenFailureModal, onUploadSuccess, handleSessionExpired }) => {  // **Added onUploadSuccess Prop**
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedSource, setSelectedSource] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isProcessing, setIsProcessing] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const modalRef = useRef(null);
    const headerRef = useRef(null);
    const uploadIntervalRef = useRef(null);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    // Reset form when modal opens
    useEffect(() => {
        if (isOpen) {
            setSelectedFile(null);
            setSelectedSource('');
            setIsUploading(false);
            setUploadProgress(0);
            setIsProcessing(false);
            setValidationErrors({});
        }
    }, [isOpen]);

    const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
            setSelectedFile(e.target.files[0]);
        }
    };

    const handleSourceChange = (e) => {
        setSelectedSource(e.target.value);
    };

    const validate = () => {
        const errors = {};

        if (!selectedFile) {
            errors.file = 'No file selected.';
        } else {
            if (selectedFile.size > 1 * 1024 * 1024 * 1024) { // 1 GB
                errors.fileSize = 'File size exceeds 1 GB limit.';
            }
            if (!selectedFile.name.endsWith('.zip')) {
                errors.fileType = 'File must be a .zip file.';
            }
        }

        if (!selectedSource) {
            errors.source = 'Please select the source of the shapefile.';
        }

        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleUpload = async () => {
        // Validate inputs
        if (!validate()) {
            handleOpenFailureModal('Please fix the errors before uploading.');
            return;
        }

        setIsUploading(true);
        setUploadProgress(0);

        const startTime = Date.now();
        const minUploadTime = 3000; // Minimum upload time in milliseconds

        // Create FormData
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('source', selectedSource);

        try {
            const response = await axios.post(`${API_BASE_URL}/upload_shapefile/`, formData, {
                headers: {
                    'Tenant-ID': String(tenantId),
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Token ${localStorage.getItem('token')}`,
                },
                onUploadProgress: (progressEvent) => {
                    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    const elapsedTime = Date.now() - startTime;
                    const timeRatio = Math.min(elapsedTime / minUploadTime, 1);
                    const adjustedPercent = Math.max(percentCompleted, Math.round(timeRatio * 100));

                    setUploadProgress(adjustedPercent);
                },
            });

            const elapsedTime = Date.now() - startTime;
            const remainingUploadTime = Math.max(minUploadTime - elapsedTime, 0);

            setTimeout(() => {
                setUploadProgress(100);
                setIsUploading(false);
                setIsProcessing(true);

                // Minimum processing time
                const minProcessingTime = 3000; // 3 seconds
                setTimeout(() => {
                    setIsProcessing(false);
                    handleOpenSuccessModal(`File uploaded successfully. Processed ${response.data.records_processed} out of ${response.data.total_records} records.`);

                    // **Invoke onUploadSuccess with Centroid Data**
                    if (response.data.centroid) {
                        onUploadSuccess(response.data.centroid);  // **Existing Line**
                    }

                    closeModal();
                }, minProcessingTime);

            }, remainingUploadTime);
        } catch (error) {
            console.error('Error uploading file:', error);
            setIsUploading(false);
            setIsProcessing(false);
            if (error.response && error.response.status === 401) {
                // Handle session expiration
                handleSessionExpired();
            } else {
                handleOpenFailureModal(error.response?.data?.error || 'Error uploading file. Please try again.');
            }
        }
    };

    const handleDragModal = useCallback((modalElement, headerElement) => {
        if (!modalElement || !headerElement) return;

        let offsetX = 0,
            offsetY = 0,
            mouseX = 0,
            mouseY = 0;

        const onMouseMove = (e) => {
            e.preventDefault();
            modalElement.style.top = `${e.clientY - offsetY}px`;
            modalElement.style.left = `${e.clientX - offsetX}px`;
        };

        const onMouseUp = () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };

        const onMouseDown = (e) => {
            if (e.target.tagName === 'BUTTON') return;

            e.preventDefault();
            mouseX = e.clientX;
            mouseY = e.clientY;

            const rect = modalElement.getBoundingClientRect();
            offsetX = mouseX - rect.left;
            offsetY = mouseY - rect.top;

            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
        };

        headerElement.addEventListener('mousedown', onMouseDown);

        return () => {
            headerElement.removeEventListener('mousedown', onMouseDown);
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };
    }, []);

    useEffect(() => {
        if (isOpen && modalRef.current && headerRef.current) {
            const cleanup = handleDragModal(modalRef.current, headerRef.current);
            return cleanup;
        }
    }, [isOpen, handleDragModal]);

    if (!isOpen) return null;

    return (
        <div className={`upload-modal ${isOpen ? 'open' : ''}`} ref={modalRef} style={{ position: 'absolute' }}>
            <div className="upload-modal-header" ref={headerRef}>
                <h2>Upload Shapefile</h2>
                <button
                    className="upload-modal-close"
                    onClick={(e) => {
                        e.stopPropagation();
                        closeModal();
                    }}
                >
                    &times;
                </button>
            </div>
            <div className="upload-modal-body">
                {isUploading ? (
                    <div className="uploading-container">
                        <p>Uploading... {uploadProgress}%</p>
                        <div className="progress-bar">
                            <div className="progress" style={{ width: `${uploadProgress}%` }}></div>
                        </div>
                    </div>
                ) : isProcessing ? (
                    <div className="uploading-container">
                        <p>Processing data, please wait...</p>
                        <div className="spinner"></div>
                    </div>
                ) : (
                    <form className="upload-form">
                        <label htmlFor="file-input" className="upload-label">Select Shapefile (.zip):</label>
                        <input
                            type="file"
                            id="file-input"
                            accept=".zip"
                            onChange={handleFileChange}
                            className={validationErrors.file || validationErrors.fileSize || validationErrors.fileType ? 'error' : ''}
                        />
                        {validationErrors.file && (
                            <span className="upload-error">{validationErrors.file}</span>
                        )}
                        {validationErrors.fileSize && (
                            <span className="upload-error">{validationErrors.fileSize}</span>
                        )}
                        {validationErrors.fileType && (
                            <span className="upload-error">{validationErrors.fileType}</span>
                        )}

                        {/* Source Selection Field */}
                        <label htmlFor="source-select" className="upload-label">Source:</label>
                        <select
                            id="source-select"
                            value={selectedSource}
                            onChange={handleSourceChange}
                            className="upload-select"
                        >
                            <option value="">-- Select Source --</option>
                            <option value="Google">Google</option>
                            <option value="Bing">Bing</option>
                            <option value="Mapbox">Mapbox</option>
                            <option value="OpenStreetMaps">OpenStreetMaps</option>
                            <option value="Others">Others</option>
                        </select>
                        {validationErrors.source && (
                            <span className="upload-error">{validationErrors.source}</span>
                        )}
                    </form>
                )}
            </div>
            {!isUploading && !isProcessing && (
                <div className="upload-modal-footer">
                    <button
                        className="upload-submit-button"
                        onClick={handleUpload}
                        disabled={!selectedFile || !selectedSource}
                    >
                        Upload
                    </button>
                    <button className="upload-cancel-button" onClick={closeModal}>Cancel</button>
                </div>
            )}
        </div>
    );
};

export default UploadShapefileModal;
