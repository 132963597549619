import React, {useState, useEffect, useRef, useCallback} from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import './SearchModel.css';

const SearchModal = ({isOpen, onClose, onFlyToLocation, handleOpenFailureModal, handleOpenSuccessModal}) => {
    const [searchOption, setSearchOption] = useState('city');
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedPoint, setSelectedPoint] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);

    const modalRef = useRef(null);
    const headerRef = useRef(null);

    const handleDragModal = useCallback((modalElement, headerElement) => {
        if (!modalElement || !headerElement) return;

        let offsetX = 0,
            offsetY = 0,
            mouseX = 0,
            mouseY = 0;

        const onMouseMove = (e) => {
            e.preventDefault();
            modalElement.style.top = `${e.clientY - offsetY}px`;
            modalElement.style.left = `${e.clientX - offsetX}px`;
        };

        const onMouseUp = () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };

        const onMouseDown = (e) => {
            if (e.target.tagName === 'BUTTON') return;

            e.preventDefault();
            mouseX = e.clientX;
            mouseY = e.clientY;

            const rect = modalElement.getBoundingClientRect();
            offsetX = mouseX - rect.left;
            offsetY = mouseY - rect.top;

            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
        };

        headerElement.addEventListener('mousedown', onMouseDown);

        return () => {
            headerElement.removeEventListener('mousedown', onMouseDown);
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };
    }, []);

    useEffect(() => {
        if (isOpen && modalRef.current && headerRef.current) {
            const cleanup = handleDragModal(modalRef.current, headerRef.current);
            return cleanup;
        }
    }, [isOpen, handleDragModal]);

    // Debounced fetch suggestions
    const fetchCitySuggestions = debounce(async (q) => {
        if (q.length < 3) {
            setSuggestions([]);
            return;
        }
        setIsLoading(true);
        try {
            const response = await axios.get('https://api.mapbox.com/geocoding/v5/mapbox.places/' + encodeURIComponent(q) + '.json', {
                params: {
                    access_token: 'pk.eyJ1Ijoic3VqaXRrdW1hcjEyMyIsImEiOiJjbTEweTh5ODMwbGl6Mm1xeTZsc3U5ZWcyIn0.EUteojtdWIqWthH0IKtU2Q',
                    types: 'place',
                    limit: 5
                }
            });
            const cities = response.data.features.map(feature => ({
                name: feature.place_name,
                lat: feature.center[1],
                lon: feature.center[0]
            }));
            setSuggestions(cities);
        } catch (err) {
            console.error("Error fetching city suggestions:", err);
            setSuggestions([]);
        } finally {
            setIsLoading(false);
        }
    }, 500);

    const fetchAimlocateAddress = async (q) => {
        if (q.length < 3) {
            setSuggestions([]);
            return;
        }
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/points/search/`, {
                aimlocate_address: q
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem('token')}`,
                    'Tenant-ID': localStorage.getItem('tenant_id')
                }
            });
            const points = response.data.map(point => ({
                id: point.id,
                lat: point.lat,
                lon: point.lon,
                physical_address: point.physical_address,
            }));
            setSuggestions(points);
        } catch (err) {
            console.error("Error fetching Aimlocate address:", err);
            setSuggestions([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (searchOption === 'city') {
            fetchCitySuggestions(query);
        } else if (searchOption === 'address') {
            fetchAimlocateAddress(query);
        } else {
            setSuggestions([]);
        }
    }, [query, searchOption]);

    const handleSearch = async () => {
        if (searchOption === 'city' && !selectedCity) {
            alert('Please select a city from suggestions.');
            return;
        }

        if (searchOption === 'address' && suggestions.length === 0) {
            alert('No results found.');
            return;
        }

        const zoomLevel = searchOption === 'city' ? 19 : 22;

        if (selectedCity) {
            onFlyToLocation(selectedCity.lat, selectedCity.lon, zoomLevel);
            onClose();
        } else if (suggestions.length === 1) {
            onClose();
            onFlyToLocation(suggestions[0].lat, suggestions[0].lon, zoomLevel);
            return;
        } else if (suggestions.length > 1 && selectedPoint) {
            onClose();
            onFlyToLocation(selectedPoint.lat, selectedPoint.lon, zoomLevel);
            return;
        } else if (suggestions.length > 1) {
            alert('Multiple results found. Please select one.');
        }
    };

    if (!isOpen) return null;

    return (
        <div className="upload-modal open" ref={modalRef}>
            <div className="upload-modal-header" ref={headerRef}>
                <h2>Search Location</h2>
                <button className="upload-modal-close" onClick={onClose}>&times;</button>
            </div>
            <div className="upload-modal-body">
                <div className="upload-form">
                    <label className="upload-label">Search by:</label>
                    <select
                        className="upload-select"
                        value={searchOption}
                        onChange={(e) => setSearchOption(e.target.value)}
                    >
                        <option value="city">City</option>
                        <option value="address">Aimlocate Address</option>
                    </select>
                    <label className="upload-label">Search Query:</label>
                    <input
                        type="text"
                        className="upload-input"
                        placeholder={`Type ${searchOption}...`}
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                    />
                    {isLoading && <p>Loading options...</p>}
                    {suggestions.length > 0 && (
                        <>
                            <label className="upload-label">Suggested {searchOption === 'city' ? 'Cities' : 'Addresses'}:</label>
                            <ul className="suggestions-list" style={{maxHeight: '150px', overflowY: 'auto'}}>
                                {suggestions.map((s, idx) => (
                                    <li
                                        key={idx}
                                        className={selectedIndex === idx ? 'selected' : ''}
                                        onClick={() => {
                                            if (searchOption === 'city') {
                                                setSelectedCity(s);
                                            } else {
                                                setSelectedPoint(s);
                                            }
                                            setSelectedIndex(idx);
                                        }}
                                    >
                                        {searchOption === 'city' ? s.name : (s.physical_address || 'Address not found')}
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
            </div>
            <div className="upload-modal-footer">
                <button className="upload-submit-button" onClick={handleSearch}>Search</button>
                <button className="upload-cancel-button" onClick={onClose}>Cancel</button>
            </div>
        </div>
    );
};

export default SearchModal;
