// ChangePasswordModal.js
import React, {useState} from 'react';
import axios from 'axios';

const ChangePasswordModal = ({
    isOpen,
    handleClose,
    onSuccess,
    onFailure,
    handleSessionExpired // New prop for handling session expiration
}) => {
    const [changePasswordData, setChangePasswordData] = useState({ old_password: '', new_password: '' });
    const [validationErrors, setValidationErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    if (!isOpen) return null;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setChangePasswordData({ ...changePasswordData, [name]: value });
    };

    const validateFields = (data, fields) => {
        const errors = {};
        fields.forEach((field) => {
            if (!data[field]) {
                errors[field] = 'This field is required';
            }
        });
        return errors;
    };

    const handleChangePassword = async () => {
        const errors = validateFields(changePasswordData, ['old_password', 'new_password']);
        setValidationErrors(errors);

        if (Object.keys(errors).length === 0) {
            setIsSubmitting(true);
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/change-password/`, changePasswordData, {
                    headers: {
                        Authorization: `Token ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                });
                onSuccess();
                handleClose();
            } catch (error) {
                console.error('Error changing password:', error);
                if (error.response && error.response.status === 401) {
                    // Handle session expiration
                    handleSessionExpired();
                } else {
                    onFailure();
                }
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    const handleCancel = () => {
        setChangePasswordData({ old_password: '', new_password: '' });
        setValidationErrors({});
        handleClose();
    };

    return (
        <div className="custom-modal-overlay">
            <div className="custom-modal">
                <h2>Change Password</h2>
                <form className="custom-form">
                    <div className="form-group">
                        <label>Old Password <span className="required">*</span></label>
                        <input
                            type="password"
                            name="old_password"
                            value={changePasswordData.old_password}
                            onChange={handleChange}
                            className={validationErrors.old_password ? 'error' : ''}
                        />
                        {validationErrors.old_password &&
                            <span className="error-message">{validationErrors.old_password}</span>}
                    </div>
                    <div className="form-group">
                        <label>New Password <span className="required">*</span></label>
                        <input
                            type="password"
                            name="new_password"
                            value={changePasswordData.new_password}
                            onChange={handleChange}
                            className={validationErrors.new_password ? 'error' : ''}
                        />
                        {validationErrors.new_password &&
                            <span className="error-message">{validationErrors.new_password}</span>}
                    </div>
                    <div className="custom-modal-footer">
                        <button type="button" className="custom-submit-btn" onClick={handleChangePassword}>Submit
                        </button>
                        <button type="button" className="custom-cancel-btn" onClick={handleClose}>Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ChangePasswordModal;
