import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import MapComponent from "./MapComponent";

// Helper function to get CSRF token from cookies
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

const App = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [tenant, setTenant] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('token') !== null);
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState(localStorage.getItem('username') || '');
  const [tenantname, setTenantname] = useState(localStorage.getItem('tenantname') || '');
  const [tenantId, setTenantId] = useState(localStorage.getItem('tenantId') || '');
  const [userId, setUserId] = useState(localStorage.getItem('userId') || '');
  const [role, setRole] = useState(localStorage.getItem('role') || null);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const [emailPlaceholder, setEmailPlaceholder] = useState('Email Address');
  const [passwordPlaceholder, setPasswordPlaceholder] = useState('Password');
  const [tenantPlaceholder, setTenantPlaceholder] = useState('Customer Name');

  // Create a ref to track if the user is logging out
  const isLoggingOutRef = useRef(false);

  // Function to check if the token is valid
  const checkTokenValidity = async () => {
    const token = localStorage.getItem('token');
    if (!token) return;

    try {
      const response = await fetch('https://app-aimlocate.org/api/v1/verifytoken/', {
        method: 'GET',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });

      // If token is not valid, handle session expiration
      if (!response.ok) {
        handleSessionExpired();
      }
    } catch (error) {
      console.error('Error verifying token:', error);
      handleSessionExpired();
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      const handleBeforeUnload = (event) => {
        if (isLoggingOutRef.current) {
          // Allow the page to unload without showing the confirmation dialog
          return;
        }
        event.preventDefault();
        // Chrome requires returnValue to be set
        event.returnValue = '';
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [isLoggedIn]);

  // Inactivity timeout and event listeners
  useEffect(() => {
    let inactivityTimeout;

    const handleActivity = () => {
      // Clear existing timeout
      clearTimeout(inactivityTimeout);
      // Set new timeout
      inactivityTimeout = setTimeout(() => {
        handleSessionExpired();
      }, 8 * 60 * 60 * 1000); // 30 minutes
    };

    if (isLoggedIn) {
      // Add event listeners
      window.addEventListener('mousemove', handleActivity);
      window.addEventListener('mousedown', handleActivity);
      window.addEventListener('keypress', handleActivity);
      window.addEventListener('touchstart', handleActivity);
      window.addEventListener('scroll', handleActivity);
      // Start the timer
      handleActivity();
    }

    return () => {
      // Clean up event listeners and timeout
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('mousedown', handleActivity);
      window.removeEventListener('keypress', handleActivity);
      window.removeEventListener('touchstart', handleActivity);
      window.removeEventListener('scroll', handleActivity);
      clearTimeout(inactivityTimeout);
    };
  }, [isLoggedIn]);

  const handleLogin = async () => {
  setErrorMessage('');
  const csrftoken = getCookie('csrftoken'); // Fetch the CSRF token from cookies

  // Initialize timeout variables
  let timeout1, timeout2;

  try {
    // Set the first timeout for 2 seconds
    timeout1 = setTimeout(() => {
      setErrorMessage('Connecting to server... please wait');
    }, 2000);

    // Set the second timeout for 4 seconds
    timeout2 = setTimeout(() => {
      setErrorMessage('Still trying to connect... please wait');
    }, 4000);

    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/login/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken, // Include the CSRF token
      },
      body: JSON.stringify({
        username: email,
        password: password,
        tenant: tenant,
      }),
      credentials: 'include', // Include cookies in the request
    });

    // Clear timeouts once the response is received
    clearTimeout(timeout1);
    clearTimeout(timeout2);

    if (response.ok) {
      const data = await response.json();
      localStorage.setItem('token', data.token);
      localStorage.setItem('tenantname', data.tenantname);
      localStorage.setItem('username', data.username);
      localStorage.setItem('role', data.role);
      localStorage.setItem('tenant_id', data.tenant_id);
      localStorage.setItem('userId', data.user_id);

      setTenantId(data.tenant_id);
      setUserId(data.user_id);
      setRole(data.role);
      setUsername(data.username); // Set username from API response
      setTenantname(data.tenantname); // Set tenant name from API response
      setIsLoggedIn(true);
      setErrorMessage(''); // Clear any previous error messages
      setIsLoggingOut(false);
    } else if (response.status === 401) {
      setErrorMessage('Invalid credentials. Please try again.');
    } else {
      const errorData = await response.json();
      setErrorMessage(errorData.detail || 'Login failed. Please try again.');
    }
  } catch (error) {
    setErrorMessage('Something went wrong. Please try again later.');
  } finally {
    // Ensure timeouts are cleared in case of any error
    clearTimeout(timeout1);
    clearTimeout(timeout2);
  }
};


  const handleLogout = () => {
    isLoggingOutRef.current = true; // Set the ref to true when logging out
    setIsLoggedIn(false);
    setIsLoggingOut(true);
    alert('Successfully logged out');
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('tenantname');
    localStorage.removeItem('tenantId');
    localStorage.removeItem('userId');
    localStorage.removeItem('role');
    localStorage.removeItem('tenant_id');
    window.location.reload();
  };

  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  // New handler for session expiration
  const handleSessionExpired = () => {
    if (!isLoggingOut) {
      isLoggingOutRef.current = true; // Prevent the confirmation dialog
      setErrorMessage('Session expired. Please login again.');
      setIsLoggedIn(false);
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      localStorage.removeItem('tenantname');
      localStorage.removeItem('tenant_id');
      localStorage.removeItem('role');
    }
  };

  if (isLoggedIn) {
    return (
      <MapComponent
        tenantId={tenantId}
        userId={userId}
        role={role}
        username={username}
        tenantname={tenantname}
        handleLogout={handleLogout}
        handleSessionExpired={handleSessionExpired}
      />
    );
  }

  return (
    <div className="background">
      <div className="left-section">
        <div className="login-container">
          <img src="/logo.png" alt="logo" className="logo" />
          <h1 className="title">Welcome Back</h1>

          {/* Display the errorMessage if set */}
          {errorMessage && <p className="error-message">{errorMessage}</p>}

          <div className="input-container">
            <i className="fa fa-envelope input-icon"></i>
            <input
              type="email"
              className="input"
              placeholder={emailPlaceholder}
              value={email}
              onFocus={() => setEmailPlaceholder('')}
              onBlur={() => setEmailPlaceholder('Email Address')}
              onChange={(e) => setEmail(e.target.value)}
              style={{ color: '#999' }} // Lighter color for placeholder
            />
          </div>

          <div className="input-container">
            <i className="fa fa-lock input-icon"></i>
            <input
              type={showPassword ? 'text' : 'password'}
              className="input"
              placeholder={passwordPlaceholder}
              value={password}
              onFocus={() => setPasswordPlaceholder('')}
              onBlur={() => setPasswordPlaceholder('Password')}
              onChange={(e) => setPassword(e.target.value)}
              style={{ color: '#999' }}
            />
            <i
              className={`fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'} password-toggle`}
              onClick={togglePasswordVisibility}
            ></i>
          </div>

          <div className="input-container">
            <i className="fa fa-industry input-icon"></i>
            <input
              type="text"
              className="input"
              placeholder={tenantPlaceholder}
              value={tenant}
              onFocus={() => setTenantPlaceholder('')}
              onBlur={() => setTenantPlaceholder('Customer Name')}
              onChange={(e) => setTenant(e.target.value)}
              style={{ color: '#999' }}
            />
          </div>

          <button className="sign-in-btn" onClick={handleLogin}>Sign In</button>
        </div>
      </div>

      <div className="right-section">
        <div className="image-container">
          <img src="/business.png" alt="buildings" className="illustration" />
        </div>
        <div className="bottom-text">
          <h2>Location Intelligence for <span className="highlight">BUSINESS</span></h2>
          <p>We live in a connected economy. The ability to see where a client is gives you the ability to make better decisions and strategic choices.</p>
        </div>
        <div className="image-container">
          <img src="/govt.png" alt="buildings" className="illustration" />
        </div>
        <div className="bottom-text">
          <h2>Location Intelligence for <span className="highlight">GOVERNMENT</span></h2>
          <p>Governments' work starts with a location. Knowing where someone is allows you to address issues and plan for the future.</p>
        </div>
      </div>
    </div>
  );
};

export default App;
